import React, { useState } from "react";
import DialogBox from "../../components/generic/DialogBox";
import AdditionalDocsModal from "../../components/Form/AdditionalDocs";
import { Button, Stack } from "@mui/material";
import VerifyDocument from "../../components/generic/verifyDocument";
import icon from "../../assets/icons/searchicon.gif";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuoteDetailAPI,
  quoteVerifyDocsAPI,
} from "../../store/action/quote";
import { DeleteOutline } from "@mui/icons-material";
import SuccessModal from "../../components/generic/Modal";
import { ReactComponent as CaseClosedIcon } from "../../assets/icons/case_closed.svg";
const VerifyFirstDocs = () => {
  const dispatch = useDispatch();
  // const [openModal, setOpenModal] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [value, setValue] = useState([]);
  const [openModalDocs, setOpenModalDocs] = useState(false);
  const [verifyDocuments, setVerifyDocuments] = useState([]); // Initialize as an empty array

  const data = useSelector((state) => state.quote.quoteDetails);
  const isReUpload =
    data?.data?.verificationPendingDocument?.length !==
    data?.data?.documents?.length;
  const verificationDocs = data?.data?.verificationPendingDocument;
  const additionalDocuments = data?.data?.additionalDocuments;
  const additionalDocumentsValue = additionalDocuments?.map((i) => i.value);
  const addDocKey = additionalDocuments
    ?.filter((item) => value?.includes(item?.value))
    ?.map((item) => item?.key);

  const handleSubmit = () => {
    const payload = {
      quoteId: data?.data?.header?.ticketId,
      documentStatusList: verifyDocuments,
      additionalDocumentList: addDocKey,
    };
    dispatch(
      quoteVerifyDocsAPI(payload, data?.data?.header?.ticketId, setOpenSuccess),
    );
  };

  return (
    <>
      {verificationDocs?.length > 0 ? (
        <div
          style={{
            width: "100%",
            borderRadius: "16px",
            backgroundColor: "#fff",
            padding: "20px 0 20px 0",
            marginRight: "30px",
            marginTop: "20px",
          }}
        >
          {openSuccess ? (
            <SuccessModal
              title="Documents Verified Successfully!"
              subTitle="Please share the quotes with partner"
              shareButton="Share Quotes"
              onClick={() =>
                dispatch(getQuoteDetailAPI(data?.data?.header?.ticketId))
              }
            />
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "530px",
                margin: "0 auto",
                gap: "10px",
              }}
            >
              <img src={icon} alt="searchImg" />
              <h2
                style={{
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#000",
                  // marginBottom: 24,
                }}
              >
                {isReUpload
                  ? "Partner has Re-uploaded the documents !"
                  : "Please Verify documents first !"}
              </h2>
              <Stack
                sx={{ width: "100%" }}
                justifyContent={"space-between"}
                gap={"16px"}
                paddingY={"16px"}
              >
                {verificationDocs?.length > 0 ? (
                  verificationDocs?.map((i, index) => (
                    <VerifyDocument
                      key={`verify-doc-${index}`}
                      data={i}
                      verifyDocuments={verifyDocuments}
                      setVerifyDocuments={setVerifyDocuments}
                      documentType={i?.documentType}
                    />
                  ))
                ) : (
                  <>
                    <p>No Data</p>
                  </>
                )}
              </Stack>
              {value &&
                value.map((item, index) => (
                  <div
                    key={`additional-docs-${index}`}
                    style={{
                      width: "100%",
                      borderRadius: 8,
                      background: "#FAFAFA",
                      padding: "8px 16px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {item}
                    <DeleteOutline
                      onClick={() => {
                        const newValue = value.filter((_, i) => i !== index);
                        setValue(newValue);
                      }}
                      style={{ color: "#FF3E78", cursor: "pointer" }}
                    />
                  </div>
                ))}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "inherit",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "8px",
                    // border: "1px solid #B3B3B3",
                    color: "#0691FC",
                    "&:hover": { backgroundColor: "#E8F3FC" },
                    textTransform: "none",
                    backgroundColor: "#E8F3FC",
                  }}
                  onClick={() => setOpenModalDocs(true)}
                >
                  Additional Doc Required
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    width: "110px",
                    borderRadius: "8px",
                    color: "#fff",
                    "&:hover": { backgroundColor: "#0691FC" },
                    backgroundColor: "#0691FC",
                    textTransform: "none",
                  }}
                  disabled={
                    verificationDocs?.length !== verifyDocuments?.length
                  }
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            width: "calc(100% - 30px)",
            borderRadius: "16px",
            backgroundColor: "#fff",
            padding: "20px 0 20px 0",
            marginRight: "30px",
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <CaseClosedIcon />
          <h2
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px",
              fontWeight: 500,
              color: "#000",
              // height: "40vh",
            }}
          >
            {data?.data?.quoteState === "CASE_CLOSED"
              ? "Case has been closed."
              : "No data found"}
          </h2>
        </div>
      )}

      {openModalDocs && (
        <DialogBox
          width={500}
          open={openModalDocs}
          setOpen={setOpenModalDocs}
          title={"Additional Docs!"}
          children={
            <AdditionalDocsModal
              setValue={setValue}
              value={value}
              data={additionalDocumentsValue}
              setOpen={setOpenModalDocs}
            />
          }
        />
      )}
    </>
  );
};

export default VerifyFirstDocs;
