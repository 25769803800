import React from "react";
import "./index.css";
import Stack from "@mui/material/Stack";
import TextInput from "../Input";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";

const Search = ({ value, onChange, placeholder, ...rest }) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <TextInput
        style={{ width: 344 }}
        placeholder={
          placeholder ? placeholder : "Search by partner name or Application ID"
        }
        value={value}
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ mt: -0.2 }} />
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </Stack>
  );
};

export default Search;
