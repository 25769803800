import axios from "axios";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";
//import {BASE_URL, JARVIS_BASE_URL} from "../api/index";
const AXIOS_HEADERS = {
  //  "Content-Type": "multipart/form-data"
  "x-version": "web.1.0.0",
};

const axiosInstance = axios.create({});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Add custom property to the request config
    // For example, you can add a custom header or any other property
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    config.headers = {
      ...AXIOS_HEADERS,
      ...config.headers,
    };
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Add custom property to the response object
    // For example, you can access response.data to check status or add any other property
    return response;
  },
  (error) => {
    // Handle response error
    if (error.response && error.response.status === 401) {
      // Optionally, you can take other actions like redirecting the user or clearing state
      window.localStorage.removeItem("token"); // Clear token from local storage
      window.localStorage.removeItem("user"); // Clear user from local storage
      message.error("Session expired. Please login again.");

      setTimeout(() => {
        // Redirect to login page
        window.location.href = "/login";
      }, 1000);
    }
    return Promise.reject(error);
  },
);

// export const axiosPrivate = axios.create({
//   baseURL: BASE_URL,
//   headers: { "Content-Type": "application/json" },
//   // withCredentials: true,
// });

// export const axiosJarvis = axios.create({
//   baseURL: JARVIS_BASE_URL,
//   headers: { "Content-Type": "application/json" },
//   // withCredentials: true,
// });

export default axiosInstance;
