import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import Search from "../../components/generic/Search/Search";
import Header from "../../components/generic/Header/Header";
import DropDown from "../../components/generic/DropDown/DropDown";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMappingListAPI,
  getMappingVerificationDetailsAPI,
} from "../../store/action/mappingRequests";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import MappingRequestTable from "../../components/Table/MappingRequestTable";
import VerifyPolicyModal from "../../components/modals/VerifyPolicyModal";
import MapPolicyModal from "../../components/modals/MapPolicyModal";
import DialogBox from "../../components/generic/DialogBox";

export default function MappingRequest() {
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.mappingRequests.mappingRequestsList,
  );
  // let token = window.localStorage.getItem("token");
  const [status, setStatus] = useState('');
  const [subStatus, setSubStatus] = useState("");
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState(data || []);
  const isLoading = useSelector(
    (state) => state.mappingRequests.isLoadingRequests,
  );
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });
  const mappingStateList = [
    {
      label: "Verify docs",
      value: "DOCUMENT_VERIFICATION_STARTED",
    },
    {
      label: "Policy Rejected, Case Closed",
      value: "POLICY_MAPPING_REJECTED",
    },
    {
      label: "Map Policy",
      value: "POLICY_DATA_PROCESSED",
    },
    {
      label: "Docs Required From Partner",
      value: "DOCUMENT_REUPLOAD",
    },
    {
      label: "Completed",
      value: "POLICY_MAPPING_COMPLETED",
    },
    {
      label: "No Sub Status",
      value: "",
    },
  ];
  const handleDateChange = (date) => {

    if (date && date.length > 0) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };

  useEffect(() => {
    dispatch(getMappingListAPI());
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      let filteredResults = data;
      if (status && status.value != "") {
        filteredResults = filteredResults?.filter((item) => {
          return item?.mappingState === status.value;
        });
      }
      if (subStatus) {
        filteredResults = filteredResults?.filter((item) => {
          return item?.product === subStatus;
        });
      }

      if (search) {
        filteredResults = filteredResults?.filter((item) => {
          const lowerCaseQuery = search.toLowerCase();
          const lowerCasePartnerName = item?.partnerName?.toLowerCase();
          const lowerCaseRegNo = item?.registrationNumber?.toLowerCase();
          const lowerCasePartnerId = item?.mappingId?.toLowerCase();

          return (
            lowerCasePartnerName?.includes(lowerCaseQuery) ||
            lowerCaseRegNo?.includes(lowerCaseQuery) ||
            lowerCasePartnerId?.includes(lowerCaseQuery)
          );
        });
      }
      if (filterByDate.startDate && filterByDate.endDate) {
        filteredResults = filteredResults?.filter((item) => {
          const startDate = new Date(filterByDate.startDate).setHours(
            0,
            0,
            0,
            0,
          );
          const endDate = new Date(filterByDate.endDate).setHours(
            23,
            59,
            59,
            999,
          );
          const itemDate = new Date(item?.mappingCreationTime).getTime();
          return itemDate >= startDate && itemDate <= endDate;
        });
      }
      setSearchResults(filteredResults || []);
    }
  }, [status, subStatus, search, filterByDate, data]);

  const handleSearch = (query) => {
    setSearch(query);
  };
  const [open, setOpen] = useState(false);
  const [ticketId, setTicketId] = useState(null);
  const [modalType, setModalType] = useState(null);
  const handleClose = () => {
    setOpen(false);
    setTicketId(null);
    setModalType(null);
  };
  const handleDialogOpen = (ticketId, type) => {
    setTicketId(ticketId);
    setModalType(type);
    setOpen(true);
    if (type === "VerifyPolicy" && ticketId)
      dispatch(getMappingVerificationDetailsAPI(ticketId));
  };

  return (
    <main className="main">
      <Header title="Mapping Requests" />
      <div className="list-wrapper">
        <header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Search
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Stack direction="row" spacing={2} alignItems="center">
              {/*eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="label">Filters :</label>
              <DropDown
                value={subStatus}
                onChange={(e) =>
                  setSubStatus(
                    e.target.value === "No Sub Status" ? "" : e.target.value,
                  )
                }
                width={145}
                placeholder="Product"
                data={[
                  "CAR",
                  "TW",
                  "GCV",
                  "PCV",
                  "BUS",
                  "MISC_D",
                  "No Sub Status",
                ]}
              />
              <DropDown
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                width={175}
                placeholder={"Select Status"}
                data={mappingStateList}
                renderOption={(option) => option.label}
                renderValue={(value) =>
                  value && value.value != "" ? value.label : "Select Status"
                }
              />
              <div className="date-picker">
                <Flatpickr
                  placeholder="Date Range"
                  options={{
                    dateFormat: "d-m-Y",
                    maxDate: new Date(),
                    mode: "range",
                  }}
                  className="flat_picker_date"
                  style={{ width: "200px", height: "41px" }}
                  value={
                    filterByDate.startDate && filterByDate.endDate
                      ? [filterByDate.startDate, filterByDate.endDate]
                      : ""
                  }
                  onChange={handleDateChange}
                />
              </div>
            </Stack>
          </Stack>
        </header>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <MappingRequestTable
            rows={searchResults}
            handleDialogOpen={handleDialogOpen}
          />
        )}
        {open && modalType === "VerifyPolicy" && (
          <DialogBox
            title={
              modalType === "VerifyPolicy" ? (
                <Typography
                  variant="h6"
                  component={"div"}
                  sx={{ flexGrow: 1, color: "#09CE1D" }}
                  textAlign="center"
                >
                  Verify Policy
                </Typography>
              ) : (
                "Map Policy"
              )
            }
            open={open}
            setOpen={setOpen}
            children={
              <VerifyPolicyModal
                ticketId={ticketId}
                handleClose={handleClose}
              />
            }
          />
        )}
        {open && modalType === "MapPolicy" && (
          <MapPolicyModal
            handleClose={handleClose}
            open={open}
            mappingId={ticketId}
          />
        )}
      </div>
    </main>
  );
}
