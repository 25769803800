import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@mui/material";
import EnhancedTableHead from "../generic/Table/EnhancedTableHead";
import { useSelector } from "react-redux";
import { mappingStates, mappingStatesBGColor } from "../../helpers/enums";
const headCells = [
  {
    id: "ticketId",
    numeric: false,
    label: "Ticket ID",
  },
  {
    id: "regNo",
    numeric: true,
    disablePadding: false,
    label: "Reg. Number",
  },
  {
    id: "partnerName",
    numeric: true,
    disablePadding: false,
    label: "Partner",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

export default function MappingRequestTable({ rows, handleDialogOpen }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("status");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = React.useMemo(
    () => stableSort(rows, getComparator(order, orderBy))?.slice(),
    [order, orderBy, rows],
  );

  // handle background color using enums for states

  const handleBGColor = (key) => {
    return mappingStatesBGColor[key] || "#FFBB0D";
  };

  const permissions = useSelector((state) => state?.auth?.permissions) || {};

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          // size={dense ? "small" : "medium"}
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowcount={rows?.length}
          />
          {rows?.length !== 0 ? (
            <TableBody>
              {visibleRows?.map((row, index) => {
                return (
                  <TableRow key={`${row}-${index}`}>
                    <TableCell align="left">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            // overflow ellipse
                            display: "-webkit-box",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: 120,
                            color: "#000",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          {row?.mappingId}
                        </span>
                        <span
                          style={{
                            color: "#808080",
                            fontSize: 12,
                            fontWeight: "400",
                          }}
                        >
                          {row?.mappingCreationTime.split("T")[0]}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          {row?.registrationNumber}
                        </span>
                        <span
                          style={{
                            color: "#808080",
                            fontSize: 12,
                            fontWeight: "400",
                          }}
                        >
                          {row?.product}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span
                          style={{
                            color: "#000",
                            fontSize: 14,
                            fontWeight: "600",
                          }}
                        >
                          {row?.partnerName}
                        </span>
                        <span
                          style={{
                            color: "#808080",
                            fontSize: 12,
                            fontWeight: "400",
                          }}
                        >
                          {row?.partnerId}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <span
                        style={{
                          background: handleBGColor(row?.mappingState),
                          borderRadius: 20,
                          color: "#fff",
                          padding: "6px 12px",
                          fontSize: 12,
                          cursor: "default",
                        }}
                      >
                        {row?.mappingStateName}
                      </span>
                    </TableCell>
                    <TableCell align="center">
                      {permissions?.OPS_MAPPING_REQUEST?.EDIT ? (
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          {mappingStates.DOCUMENT_VERIFICATION_STARTED ===
                            row?.mappingState && (
                            <span
                              role="button"
                              style={{
                                color: "#0691FC",
                                background: "#E8F3FC",
                                padding: "12px 16px",
                                cursor: "pointer",
                                // width: 120,
                                borderRadius: 8,
                              }}
                              onClick={() =>
                                handleDialogOpen(row?.mappingId, "VerifyPolicy")
                              }
                              tabIndex={0}
                            >
                              Verify Policy
                            </span>
                          )}
                          {mappingStates.POLICY_DATA_PROCESSED ===
                            row?.mappingState && (
                            <span
                              role="button"
                              style={{
                                color: "#0691FC",
                                background: "#E8F3FC",
                                padding: "12px 16px",
                                cursor: "pointer",
                                borderRadius: 8,
                              }}
                              onClick={() =>
                                handleDialogOpen(row?.mappingId, "MapPolicy")
                              }
                              tabIndex={0}
                            >
                              Map Policy
                            </span>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="left">
                  <Typography sx={{ my: 10 }}>No Data Found.</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
