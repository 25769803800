import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export default function BookingCard() {
  return (
    <Box sx={{
        borderRadius: "12px",
        width: "518px",
        height: "202px",
       
        border: "0px solid transparent",
      }}>
      <Card>
        <CardContent>
          <Typography
            component="div"
            sx={{
              display: "flex",
              justifyContent: " space-between",

              marginTop: "5px",
            }}
          >
            <Typography component="span" sx={{ background: "grey" }}>
              <img src="sss " alt="ss" />
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "21px " }}
            >
              DL-03C-1232
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  lineHeight: "18px",
                  color: "rgba(24, 24, 24, 1)",

                
                }}
            
              >
                Hyundaiverna
              </p>
            </Typography>
            <br />

            <Typography>ddd</Typography>
            <Typography>ddd</Typography>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
