import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DropDown from "../DropDown/DropDown";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const VerifyDocument = ({
  key,
  data,
  verifyDocuments,
  setVerifyDocuments,
  documentType,
  isCheque = false
}) => {
  const [isValid, setIsValid] = useState(null);
  const [isInvalidated, setIsInvalidated] = useState(false);
  const [reason, setReason] = useState("");

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  const handleReason = (selectedReason) => {
    setReason(selectedReason);
  };

  const handleClickValid = () => {
    setIsValid(true);

    const updatedDocument = {
      documentType: data?.documentType,
      documentStatus: "VERIFIED",
    };

    // Check if documentType is already present in verifyDocuments
    const isDocumentTypePresent = verifyDocuments.some(
      (document) => document.documentType === updatedDocument.documentType,
    );

    if (!isDocumentTypePresent) {
      // If documentType is not present, update verifyDocuments
      setVerifyDocuments([...verifyDocuments, updatedDocument]);
    }
    // If documentType is already present, do nothing (optional: you can handle this case as needed)
  };

  const handleClickInValid = () => {
    const updatedDocument = {
      documentType: data?.documentType,
      documentStatus: "REUPLOAD",
      documentReason: reason,
    };
    setVerifyDocuments([...verifyDocuments, updatedDocument]); // Spread operator to add the new document
  };

  const handleDeleteDocument = () => {
    const updatedDocuments = verifyDocuments.filter(
      (document) => document?.documentType !== documentType,
    );
    setVerifyDocuments(updatedDocuments);
  };

  return (
    <div
      key={key}
      style={{
        width: "100%",
        height: 42,
        borderRadius: 8,
        background: "#FAFAFA",
        padding: "4px 16px",
        gap: 16,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {!isCheque && (
        <Typography sx={{ fontSize: 12, color: "#000", fontWeight: 500 }}>
          {data?.documentName}
        </Typography>
        )}

        {isValid === null ? (
          <Box display="flex" flexDirection="row" gap={1}>
            {!isCheque ? (
            <Button
              onClick={() => openInNewTab(data?.documentUrl)}
              startIcon={<VisibilityIcon />}
              sx={{
                color: "#0691FC",
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              View Doc
            </Button>
            ): ''
          }
            {data?.documentStatus !== "VERIFIED" && (
              <Button
                onClick={handleClickValid}
                startIcon={<DoneIcon />}
                sx={{
                  color: "#23963D",
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
              >
                Valid
              </Button>
            )}
            {data?.documentStatus !== "VERIFIED" && (
              <Button
                onClick={() => setIsValid(false)}
                startIcon={<CloseIcon />}
                sx={{
                  color: "#DF0000",
                  fontSize: 12,
                  textTransform: "capitalize",
                }}
              >
                Not Valid
              </Button>
            )}
          </Box>
        ) : isValid ? (
          <Box display="flex" flexDirection="row">
            <Box flexGrow={1} />
            <Button
              startIcon={<CheckCircleIcon />}
              sx={{
                color: "#52CE6E",
                fontSize: 12,
                fontWeight: 500,
                textTransform: "capitalize",
                backgroundColor: "var(--color-green-40)",
                //add this background color to hover also
                "&:hover": {
                  backgroundColor: "var(--color-green-40)",
                },
              }}
              variant="text"
            >
              Validated
            </Button>
            <Button
              onClick={() => {
                setIsValid(null);
                handleDeleteDocument();
              }}
              sx={{
                color: "#0691FC",
                fontSize: 12,
                textTransform: "capitalize",
              }}
            >
              Edit
            </Button>
          </Box>
        ) : (
          <>
            {isInvalidated ? (
              <Box display="flex" flexDirection="row">
                <Box flexGrow={1} />
                <Button
                  variant="text"
                  onClick={() => setIsValid(false)}
                  startIcon={<CloseIcon />}
                  sx={{
                    color: "#DF0000",
                    // mx: 2,
                    fontSize: 12,
                    minWidth: "unset",
                    textTransform: "capitalize",
                    backgroundColor: "var(--color-red-40)",
                    //add this background color to hover also
                    "&:hover": {
                      backgroundColor: "var(--color-red-40)",
                    },
                  }}
                >
                  Invalidated
                </Button>
                <Button
                  onClick={() => {
                    setIsValid(null);
                    setIsInvalidated(false);
                    handleDeleteDocument();
                    setReason("");
                  }}
                  sx={{
                    color: "#0691FC",
                    fontSize: 12,
                    textTransform: "capitalize",
                  }}
                >
                  Edit
                </Button>
              </Box>
            ) : (
              <Box display="flex" flexDirection="row" alignItems={"center"}>
                <Box flexGrow={1} />
                <DropDown
                  value={reason}
                  onChange={(e) => handleReason(e.target.value)}
                  data={
                    data?.documentDeclineReasons || [
                      "Document Not Clear",
                      "Wrong Document",
                      "Wrong Document Format",
                    ]
                  }
                  placeholder="Select Reason"
                  height={28}
                />
                <Button
                  variant="text"
                  onClick={() => {
                    setIsInvalidated(true);
                    handleClickInValid();
                  }}
                  disabled={!reason}
                  sx={{
                    color: "#DF0000",
                    mx: 2,
                    fontSize: 12,
                    minWidth: "unset",
                    textTransform: "capitalize",
                  }}
                >
                  Submit
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </div>
  );
};

export default VerifyDocument;
