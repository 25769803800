import React from "react";
import Lottie from "react-lottie";

const LottiePlayer = ({ image, height, width }) => {
  const defaultLottieOptions = {
    loop: true,
    autoplay: true,
    animationData: image,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Lottie options={defaultLottieOptions} height={height} width={width} />
  );
};

export default LottiePlayer;
