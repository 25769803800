import React from "react";
import Header from "../../components/generic/Header/Header";
import { Stack } from "@mui/material";
import Search from "../../components/generic/Search/Search";
import Flatpickr from "react-flatpickr";
import DropDown from "../../components/generic/DropDown/DropDown";
// import "flatpickr/dist/flatpickr.css";
// import newQuoteRequestHeader from "../../helpers/enums";
import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import BookingTabs from "./BookingTab/BookingTab";
import BookingCard from "./BookingCard/BookingCard"

const BookingPage = () => {

  const [status, setStatus] = useState("");

  return (
    <div className="main">
      <Header title="BOOKINGS" />
      <div>
        <div className="booking-filters-item">
          <Search />

          <Stack direction="row" spacing={2} alignItems="center">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="label">Filters :</label>
            <DropDown
              value={status}
              onChange={(e) =>
                setStatus(e.target.value === "No Status" ? "" : e.target.value)
              }
              width={175}
              placeholder="Select Partner"
              data={["No Status", "KYC Pending", "Payment Pending"]}
            />
            <div className="date-picker">
              <Flatpickr
                placeholder="Date Range"
                options={{
                  dateFormat: "d-m-Y",
                  maxDate: new Date(),
                  mode: "range",
                }}
                className="flat_picker_date"
                style={{ width: "200px", height: "41px" }}
              />
            </div>
          </Stack>
        </div>
        <div>
          <BookingTabs />
        </div>
        <br/>
        <div>
          <BookingCard />
        </div>
      </div>
    </div>
  );
};

export default BookingPage;
