import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getMappingPolicyOptionsAPI,
  mapPolicyCopyAPI,
  mapPolicyRejectAPI,
} from "../../store/action/mappingRequests";
import Heading from "../generic/Heading/Heading";
import TextInput from "../generic/Input";
import DropDown from "../generic/DropDown/DropDown";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import MapPolicyHeader from "../generic/MapPolicyHeader";
import VerifyDocument from "../generic/verifyDocument";
import { CheckCircle } from "@mui/icons-material";
import SuccessModal from "../generic/Modal";
import { CircularProgress } from "@mui/material";
import DialogBox from "../generic/DialogBox";
import AutoComplete from "../generic/AutoComplete/AutoComplete";

const inputFieldType = Object.freeze({
  TEXTBOX: "TEXTBOX",
  LIST: "LIST",
  MULTI_LIST: "MULTI_LIST",
  BOOLEANBOX: "BOOLEANBOX",
  DATE: "DATE",
  NUMBER: "NUMBER",
  SEARCH_LIST: "SEARCH_LIST",
});

const inputHandler = (
  type,
  fieldName,
  value,
  onValueChange,
  optionsList,
  isMandatory,
  apiName,
  error,
) => {
  switch (type) {
    case inputFieldType.TEXTBOX:
      return (
        <TextInput
          label={fieldName}
          value={value}
          onChange={(e) => onValueChange(apiName, e.target.value)}
          error={error}
          isMandatory={isMandatory}
        />
      );
    case inputFieldType.LIST:
      return (
        <Box flexDirection={"column"}>
          <Typography variant="caption">
            {fieldName}
            {isMandatory && "*"}
          </Typography>
          <DropDown
            placeholder={fieldName}
            value={value || ""}
            onChange={(e) => onValueChange(apiName, e.target.value)}
            data={optionsList}
            error={error}
          />
        </Box>
      );
    case inputFieldType.MULTI_LIST:
      return (
        <Box flexDirection={"column"}>
          <Typography variant="caption">
            {fieldName}
            {isMandatory && "*"}
          </Typography>
          <DropDown
            multiple
            placeholder={fieldName}
            value={value || []}
            onChange={(e) => onValueChange(apiName, e.target.value)}
            data={optionsList}
            error={error}
          />
        </Box>
      );

    case inputFieldType.SEARCH_LIST:

      return (
        <Box flexDirection={"column"}>
          <Typography variant="caption">
            {fieldName}
            {isMandatory && "*"}
          </Typography>
          <AutoComplete
            placeholder={fieldName}
            value={value || ""}
            onChange={(selectedOptions) => onValueChange(apiName, selectedOptions)}
            data={optionsList}
            error={error}
          />
        </Box>
      );
      
    case inputFieldType.BOOLEANBOX:
      return (
        <Box flexDirection={"column"}>
          <Typography variant="caption">
            {fieldName}
            {isMandatory && "*"}
          </Typography>
          <DropDown
            placeholder={fieldName}
            value={value === true ? "Yes" : value === false ? "No" : ""}
            onChange={(e) =>
              onValueChange(apiName, e.target.value, inputFieldType.BOOLEANBOX)
            }
            data={["Yes", "No"]}
            error={error}
          />
        </Box>
      );
    case inputFieldType.DATE:
      return (
        <Box flexDirection={"column"}>
          <Typography variant="caption">
            {fieldName}
            {isMandatory && "*"}
          </Typography>
          <div className={`date-picker ${error ? "date-picker-error" : ""}`}>
            <Flatpickr
              options={{
                dateFormat: "d-m-Y",
                allowInput: true,
                disableMobile: true,
                // defaultDate: value,
              }}
              className="flat_picker_date"
              style={{ width: "100%", height: "41px" }}
              value={value}
              onChange={(date) => onValueChange(apiName, date[0])}
            />
          </div>
        </Box>
      );
    case inputFieldType.NUMBER:
      return (
        <TextInput
          type="number"
          label={fieldName}
          value={value}
          onChange={(e) =>
            onValueChange(apiName, e.target.value, inputFieldType.NUMBER)
          }
          error={error}
          isMandatory={isMandatory}
        />
      );
    default:
      return (
        <TextInput
          label={fieldName}
          value={value}
          onChange={(e) => onValueChange(apiName, e.target.value)}
          error={error}
        />
      );
  }
};

const MapPolicyModal = ({ mappingId, handleClose, open }) => {
  const [bookingDetails, setBookingDetails] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState({});

  const [openSuccess, setOpenSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [reject, setReject] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const {
    bookingOptions,
    vehicleOptions,
    isLoadingDetails,
    headerOptions,
    docs,
  } = useSelector((state) => state.mappingRequests);
  const dispatch = useDispatch();
  useEffect(() => {
    if (mappingId) {
      dispatch(getMappingPolicyOptionsAPI(mappingId));
    }
  }, [dispatch, mappingId]);

  useEffect(() => {
    if (bookingOptions.length > 0) {
      const bookingDetails = {};
      // bookingOptions.forEach((option) => {
      for (let option of bookingOptions) {
        bookingDetails[option.apiField] = {
          value: option.default,
          error: false,
          isMandatory: option.isMandatory,
        };
      }
      setBookingDetails(bookingDetails);
    }
    if (vehicleOptions.length > 0) {
      const vehicleDetails = {};
      // vehicleOptions.forEach((option) => {
      for (let option of vehicleOptions) {
        vehicleDetails[option.apiField] = {
          value: option.default,
          error: false,
          isMandatory: option.isMandatory,
        };
      }
      setVehicleDetails(vehicleDetails);
    }

  }, [bookingOptions, vehicleOptions, headerOptions]);
  const onBookingDetailsChange = (apiName, value, fieldType) => {
    if (apiName == "policyEndDate") {
      if (bookingDetails["policyStartDate"].value > value) {
        setBookingDetails({
          ...bookingDetails,
          [apiName]: { ...bookingDetails[apiName], value: value, error: true },
        });
      } else {
        setBookingDetails({
          ...bookingDetails,
          [apiName]: { ...bookingDetails[apiName], value: value },
        });
      }
    } else if (apiName == "policyStartDate") {
      if (bookingDetails["policyEndDate"].value < value) {
        setBookingDetails({
          ...bookingDetails,
          [apiName]: { ...bookingDetails[apiName], value: value, error: true },
        });
      } else {
        setBookingDetails({
          ...bookingDetails,
          [apiName]: { ...bookingDetails[apiName], value: value },
        });
      }
    } else {
      if (fieldType == inputFieldType.BOOLEANBOX) {
        value = value == "Yes" ? true : false;
      }
      if (fieldType == inputFieldType.NUMBER) {
        value = parseInt(value);
      }
      setBookingDetails({
        ...bookingDetails,
        [apiName]: { ...bookingDetails[apiName], value: value },
      });
    }
  };
  const onVehicleDetailsChange = (apiName, value, fieldType) => {
    if (fieldType == inputFieldType.BOOLEANBOX) {
      value = value == "Yes" ? true : false;
    }
    if (fieldType == inputFieldType.NUMBER) {
      value = parseInt(value);
    }
    setVehicleDetails({
      ...vehicleDetails,
      [apiName]: { ...vehicleDetails[apiName], value: value },
    });
  };
  const handleSubmit = () => {
    let isBookingDetailsValid = true;
    let isVehicleDetailsValid = true;
    let errorList = {};
    for (let key in bookingDetails) {
      if (
        bookingDetails[key].isMandatory &&
        (bookingDetails[key].value === null || bookingDetails[key].value === "")
      ) {
        isBookingDetailsValid = false;
        errorList[key] = true;
      }
    }
    for (let key in vehicleDetails) {
      if (
        vehicleDetails[key].isMandatory &&
        (vehicleDetails[key].value === null || vehicleDetails[key].value === "")
      ) {
        isVehicleDetailsValid = false;
        errorList[key] = true;
      }
    }
    if (isBookingDetailsValid && isVehicleDetailsValid) {
      let data = { mappingId: mappingId };
      for (let key in bookingDetails) {
        if (bookingDetails[key].value !== "")
          data[key] = bookingDetails[key].value;
      }
      for (let key in vehicleDetails) {
        if (vehicleDetails[key].value !== "")
          data[key] = vehicleDetails[key].value;
      }
      dispatch(mapPolicyCopyAPI(data, setOpenSuccess));
    } else {
      setErrors(errorList);
    }
    // setOpenSuccess(true);
  };
  const handleReject = () => {
    setReject(true);
  };
  const handleRejectConfirm = () => {
    let data = { mappingId: mappingId, rejectReason: rejectReason };
    dispatch(mapPolicyRejectAPI(data, setOpenSuccess));
    setReject(false);
  };
  return (
    <>
      <Dialog
        PaperProps={openSuccess && { sx: { borderRadius: "32px" } }}
        fullScreen={!openSuccess}
        open={open}
      >
        {/* <DialogTitle>Map Policy</DialogTitle> */}
        {openSuccess ? (
          <Box
            borderRadius={4}
            // borderRadius={"2rem"}

            width={"100%"}
            height={"100%"}
            padding={"2rem"}
          >
            <SuccessModal
              title={
                !rejectReason
                  ? "Policy Mapped Successfully"
                  : "Docs Invalidated Successfully"
              }
              // subTitle={"Policy has been Mapped successfully"}
              shareButton={"All Requests"}
              btnDisabled={false}
              onClick={() => {
                setOpenSuccess(false);
                handleClose();
              }}
            />
          </Box>
        ) : isLoadingDetails ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div>
            <MapPolicyHeader
              headerOptions={headerOptions}
              handleClose={handleClose}
            />
            <DialogContent
              style={{ backgroundColor: "var(--color-grey-light)" }}
            >
              <Stack direction="row" gap={2}>
                <Box alignItems={"center"} direction="column" maxWidth={"50%"}>
                  <Heading
                    style={{ marginBottom: "2rem" }}
                    type={"withborder small"}
                    text={"Booking Details"}
                  />
                  <Box
                    sx={{
                      padding: "1rem",
                      backgroundColor: "var(--color-white)",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      borderRadius: "var(--border-radius-8)",
                    }}
                  >
                    <Grid container columns={2} spacing={2}>
                      {bookingOptions &&
                        bookingOptions.length > 0 &&
                        bookingOptions.map((option, index) => (
                          // 2 columns per row
                          <Grid item xs={1} 
                            key={`booking-option-${index}`}
                          >
                            {inputHandler(
                              option.fieldType,
                              option.fieldName,
                              bookingDetails[option.apiField]?.value,
                              onBookingDetailsChange,
                              option.options,
                              option.isMandatory,
                              option.apiField,
                              errors[option.apiField] || false,
                            )}
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </Box>
                <Box
                  direction="column"
                  flexDirection={"column"}
                  display={"flex"}
                  maxWidth={"50%"}
                  justifyContent={"space-between"}
                >
                  <Box direction="column" sx={{ width: "100%" }}>
                    <Box direction="column" sx={{ width: "100%" }}>
                      <Heading
                        style={{ marginBottom: "2rem" }}
                        type={"withborder small"}
                        text={"Vehicle Details"}
                      />
                      <Box
                        sx={{
                          padding: "1rem",
                          backgroundColor: "var(--color-white)",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          borderRadius: "var(--border-radius-8)",
                        }}
                      >
                        <Grid container columns={2} spacing={2}>
                          {vehicleOptions &&
                            vehicleOptions.length > 0 &&
                            vehicleOptions.map((option,index) => (
                              <Grid item xs={1}
                                key={`vehicle-option-${index}s`}
                              >
                                {inputHandler(
                                  option.fieldType,
                                  option.fieldName,
                                  vehicleDetails[option.apiField]?.value,
                                  onVehicleDetailsChange,
                                  option.options,
                                  option.isMandatory,
                                  option.apiField,
                                  errors[option.apiField] || false,
                                )}
                              </Grid>
                            ))}
                        </Grid>
                      </Box>
                    </Box>
                    <Box direction="column" sx={{ width: "100%" }}>
                      <Heading type={"withborder small"} text={"Documents"} />
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        padding={"1rem"}
                        gap={"1rem"}
                        marginY={"1rem"}
                        height={"min-content"}
                        fullWidth
                        sx={{
                          // margin: "1rem",
                          padding: "1rem",
                          flexGrow: 1,
                          backgroundColor: "var(--color-white)",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                          // border: "1px solid var(--color-grey)",
                          borderRadius: "var(--border-radius-8)",
                        }}
                      >
                        {docs &&
                          docs.map((i, index) => (
                            <VerifyDocument
                              key={`verify-doc-${index}`}
                              data={i}
                              documentType={i?.documentType}
                            />
                          ))}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    sx={{ marginTop: "1rem" }}
                  >
                    <Button
                      onClick={handleReject}
                      style={{
                        backgroundColor: "var(--color-red)",
                        textTransform: "none",

                        color: "#fff",
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontWeight: 600,
                        width: "116px",
                        height: "45px",
                      }}
                    >
                      Reject
                    </Button>
                    <Button
                      startIcon={<CheckCircle />}
                      onClick={handleSubmit}
                      sx={{ paddingX: "1rem" }}
                      style={{
                        backgroundColor: "#0691FC",
                        textTransform: "none",

                        color: "#fff",
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontWeight: 600,
                        // width: "116px",
                        height: "45px",
                      }}
                    >
                      Map Policy
                    </Button>
                  </Box>
                </Box>
              </Stack>
            </DialogContent>
          </div>
        )}
      </Dialog>
      {reject && (
        <DialogBox
          width={"451px"}
          open={reject}
          setOpen={() => {
            setReject(false);
            setRejectReason("");
          }}
          children={
            <Box
              marginX={"auto"}
              gap={"1rem"}
              alignItems={"center"}
              width={"60%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              height={"100%"}
            >
              <DropDown
                placeholder={"Select Reason"}
                onChange={(e) => setRejectReason(e.target.value)}
                value={rejectReason || ""}
                data={["Document not clear", "Invalid Document"]}
                error={false}
                label={"Reason"}
              />
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                sx={{ marginTop: "1rem" }}
              >
                <Button
                  onClick={handleRejectConfirm}
                  sx={{ paddingX: "1rem" }}
                  style={{
                    backgroundColor: "var(--color-red)",
                    textTransform: "none",

                    color: "#fff",
                    borderRadius: "8px",
                    fontSize: "14px",
                    fontWeight: 600,
                    width: "116px",
                    height: "45px",
                  }}
                >
                  Reject
                </Button>
              </Box>
            </Box>
          }
          title={""}
        />
      )}
    </>
  );
};

export default MapPolicyModal;
