import { TextField, Typography } from "@mui/material";
import React from "react";
function TextInput({ label, isMandatory, ...rest }) {
  return (
    <>
      <Typography variant="caption">
        {label} {isMandatory && "*"}
      </Typography>
      <TextField
        variant="outlined"
        fullWidth
        sx={{
          border: "1px solid #8B8B8B",
          backgroundColor: "#fff",
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
          //error
          ".MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #F44336",
          },
          borderRadius: 2,
        }}
        disableunderline="true"
        size="small"
        {...rest}
      />
    </>
  );
}

export default TextInput;
