import { Box, Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DropDown from "../generic/DropDown/DropDown";
import Heading from "../generic/Heading/Heading";
import {
  verifyCheckAPI,
  verifyPartnerDocAPI,
} from "../../store/action/partner";
import VerifyBankDetails from "./VerifyBankDetails";
import HigherEducation from "./HigherEducation";
import PosStatus from "./PosStatus";
import IIBStatus from "./IIBStatus";
// import NocDetails from "./NocDetails";

export default function CustomForm({
  setOpen,
  setOpenModal,
  id,
  isBank,
  setIsVerify,
}) {
  const dispatch = useDispatch();
  // const payment = useSelector((state) => state.partner?.partnerPayment);
  const partnerNoc = useSelector((state) => state.partner?.partnerNoc);
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  const [pos, setPos] = useState(null);
  const [education, setEducation] = useState(null);
  const [iibStatus, setIibStatus] = useState(null);
  const [reason, setReason] = React.useState("");
  const [bank, setBank] = React.useState(null);
  const [bankReason, setBankReason] = React.useState("");
  const [iibData, setIibData] = useState([
    {
      insurerType: "",
      insurerName: "",
      iibStatus: "",
    },
  ]);

  const [verifyDocument, setVerifyDocument] = useState([]);
  const [verifyNocDocument, setVerifyNocDocument] = useState([
    {
      documentType: "",
      documentStatus: "",
      documentReason: "",
    },
    {
      documentType: "",
      documentStatus: "",
      documentReason: "",
    },
    {
      documentType: "",
      documentStatus: "",
      documentReason: "",
    },
    {
      documentType: "",
      documentStatus: "",
      documentReason: "",
    },
    {
      documentType: "",
      documentStatus: "",
      documentReason: "",
    },
  ]);

  let veriFyNocDoc = verifyNocDocument?.filter(
    (i) => i?.documentStatus === "VERIFIED" || i?.documentStatus === "REUPLOAD",
  );
  const [nocType, setNocType] = useState([
    { types: "null" },
    { types: "null" },
    { types: "null" },
    { types: "null" },
    { types: "null" },
  ]);
  // Function to update the second object in the array
  const updateSecondObject = (index, val) => {
    // Create a new array with the same objects
    const updatedNocType = [...nocType];

    // Update the second object at index 1 (since arrays are zero-based)
    updatedNocType[index] = { types: val };

    // Set the updated array as the new state
    setNocType(updatedNocType);
  };

  //  to noc reason
  const handleNocReason = (value, formKey, indx, type, condition) => {
    const orgData = [...verifyNocDocument];
    orgData[indx][formKey] = value;
    orgData[indx]["documentStatus"] = condition ? "VERIFIED" : "REUPLOAD";
    orgData[indx]["documentType"] = type;
    setVerifyNocDocument([...orgData]);
  };

  const posWidget = partnerNoc?.data?.posStatusWidget?.showWidget;
  const iibWidget = partnerNoc?.data?.iibStatusWidget?.showWidget;
  const educationWidget =
    partnerNoc?.data?.higherEducationDocumentWidget?.showWidget;
  const nocWidgetsWidget = partnerNoc?.data?.nocWidgets?.showWidget;
  // main function to call api for noc verification
  const handleNocSubmit = () => {
    let nocData = {
      partnerId: id,
    };

    if (posWidget && pos !== null) {
      nocData = {
        ...nocData,
        posRecordFound: pos,
      };
    }
    if (iibWidget && iibStatus === null) {
      nocData = {
        ...nocData,
      };
    }
    if (iibWidget && iibStatus !== null) {
      nocData = {
        ...nocData,
        iibRecordFound: iibStatus,
      };
    }
    if (
      iibWidget &&
      iibData[0]?.insurerType &&
      iibData[0]?.insurerName &&
      iibData[0]?.iibStatus
    ) {
      nocData = {
        ...nocData,
        iibRecordFound: !iibStatus,
        iibData: !iibStatus ? iibData : [],
      };
    }
    if (educationWidget && verifyDocument?.length) {
      nocData = {
        ...nocData,
        verifyDocument: verifyDocument,
      };
    }
    if (nocWidgetsWidget && veriFyNocDoc?.length) {
      nocData = {
        ...nocData,
        verifyDocument: [...verifyDocument, ...veriFyNocDoc],
      };
    }

    dispatch(verifyPartnerDocAPI(nocData, setOpenModal, setIsVerify));
    setOpen(false);
  };

  // main function to call api for bank verification
  const handlePaymentSubmit = () => {
    const bankData = {
      partnerId: id,
      documentStatus: bank ? "VERIFIED" : "REUPLOAD",
      reason: bankReason,
    };
    dispatch(verifyCheckAPI(bankData, setOpenModal, setIsVerify));
    setOpen(false);
  };
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const nocDisable = nocType?.filter(
    (i) => i?.types === "true" || i?.types === "false",
  );
  useEffect(() => {
    // Check your conditions here, e.g., both condition1 and condition2 must be true to disable the button
    setButtonDisabled(
      pos === null &&
        education === null &&
        iibStatus &&
        !iibData[0]?.insurerType &&
        !iibData[0]?.insurerName &&
        !iibData[0]?.iibStatus &&
        !nocDisable?.length,
    );
  }, [pos, education, iibStatus, !iibData[0]?.insurerType, nocDisable]);

  return (
    <Box sx={{ textTransform: "capitalize" }}>
      {/* For PAN Information  */}
      <Heading type={"withborder"} text="PAN Details" />
      <Box
        sx={{
          background: "var(--color-neutral-800)",
          width: "100%",
          borderRadius: "var(--base-space-4)",
          marginBottom: "var(--base-space-4)",
          padding: "var(--base-space-4)",
        }}
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <span
              style={{
                fontSize: 12,
                marginBottom: 8,
                color: "var(--color-gray-190)",
              }}
            >
              Full Name:
            </span>
            <Typography
              style={{
                fontSize: "var(--font-regular)",
                color: "var(--color-black-2)",
                fontWeight: "var(--font-medium)",
              }}
            >
              {partnerNoc?.data?.panDetails?.name}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <span
              style={{
                fontSize: 12,
                marginBottom: 8,
                color: "var(--color-gray-190)",
              }}
            >
              Pan Number:
            </span>
            <Typography
              style={{
                fontSize: "var(--font-regular)",
                color: "var(--color-black-2)",
                fontWeight: "var(--font-medium)",
              }}
            >
              {partnerNoc?.data?.panDetails?.panNumber}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column">
            <span
              style={{
                fontSize: 12,
                marginBottom: 8,
                color: "var(--color-gray-190)",
                width: 70,
              }}
            >
              DOB:
            </span>
            <Typography
              style={{
                fontSize: "var(--font-regular)",
                color: "var(--color-black-2)",
                fontWeight: "var(--font-medium)",
              }}
            >
              {partnerNoc?.data?.panDetails?.dob}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* For IIB Status  */}

      {partnerNoc?.data?.iibStatusWidget?.showWidget && (
        <IIBStatus
          iibData={iibData}
          setIibData={setIibData}
          iibStatus={iibStatus}
          setIibStatus={setIibStatus}
          partnerNoc={partnerNoc}
        />
      )}

      {/* For POS Status  */}

      {partnerNoc?.data?.posStatusWidget?.showWidget && (
        <PosStatus pos={pos} setPos={setPos} partnerNoc={partnerNoc} />
      )}
      {/* For Higher Education Department  */}

      {partnerNoc?.data?.higherEducationDocumentWidget?.showWidget && (
        <HigherEducation
          reason={reason}
          setReason={setReason}
          education={education}
          setEducation={setEducation}
          setVerifyDocument={setVerifyDocument}
          partnerNoc={partnerNoc}
        />
      )}
      {/* For Bank Details  */}
      {partnerNoc?.data?.bankDetails?.bankBeneficiaryName && (
        <VerifyBankDetails
          bank={bank}
          bankReason={bankReason}
          setBank={setBank}
          setBankReason={setBankReason}
          partnerNoc={partnerNoc}
        />
      )}

      {/* NOC Details  */}
      {partnerNoc?.data?.nocWidgets?.showWidget && (
        <Box>
          <Heading type={"withborder"} text="NOC Details" />
          <Box
            sx={{
              background: "#F7F7F7",
              width: "100%",
              borderRadius: 5,
              marginBottom: 2,
              padding: 2,
            }}
          >
            {partnerNoc?.data?.nocWidgets?.nocWidgetList?.map((i, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  pb:
                    index !==
                      partnerNoc?.data?.nocWidgets?.nocWidgetList?.length - 1 && partnerNoc?.data?.nocWidgets?.nocWidgetList[index]?.showWidget &&
                    "12px",
                  borderBottom:
                    index !==
                      partnerNoc?.data?.nocWidgets?.nocWidgetList?.length - 1 && partnerNoc?.data?.nocWidgets?.nocWidgetList[index]?.showWidget &&
                    "1px solid #e7e7e7",
                  mb:
                    index !==
                      partnerNoc?.data?.nocWidgets?.nocWidgetList?.length - 1 && partnerNoc?.data?.nocWidgets?.nocWidgetList[index]?.showWidget &&
                    "12px",
                }}
              >
                {nocType[index]?.types === "null" ? (
                  <>
                    {i?.showWidget && (
                      <Typography sx={{ fontSize: "14px", mb: 3 }}>
                        {i?.name}
                      </Typography>
                    )}

                    {i?.showWidget && (
                      <Box display="flex" flexDirection="row" gap={1}>
                        <Box>
                          <Button
                            onClick={() => openInNewTab(i?.url)}
                            startIcon={<VisibilityIcon />}
                            sx={{
                              background: "#EEF6FD",
                              color: "#0691FC",
                              width: 180,
                              fontSize: 12,
                              padding: "12px",
                              borderRadius: 2,
                              textTransform: "capitalize",
                            }}
                          >
                            View Document
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            onClick={() => {
                              updateSecondObject(index, "true");
                              handleNocReason(
                                null,
                                "documentReason",
                                index,
                                i?.widgetType,
                                true,
                              );
                            }}
                            startIcon={<DoneIcon />}
                            sx={{
                              background: "#E2F1E6",
                              color: "#23963D",
                              fontSize: 12,
                              padding: "12px",
                              borderRadius: 2,
                              textTransform: "capitalize",
                            }}
                            fullWidth
                          >
                            Valid
                          </Button>
                        </Box>
                        <Button
                          onClick={() => updateSecondObject(index, "false")}
                          startIcon={<CloseIcon />}
                          sx={{
                            background: "#F9E5E5",
                            color: "#DF0000",
                            fontSize: 12,
                            padding: "12px",
                            borderRadius: 2,
                            textTransform: "capitalize",
                          }}
                          fullWidth
                        >
                          Invalid
                        </Button>
                      </Box>
                    )}
                  </>
                ) : nocType[index]?.types === "true" ? (
                  <>
                    {i?.showWidget && (
                      <Typography sx={{ fontSize: "14px", mb: 3 }}>
                        {i?.name}
                      </Typography>
                    )}

                    {i?.showWidget && (
                      <Box display="flex" flexDirection="row" gap={1}>
                        <Box>
                          <Button
                            onClick={() => updateSecondObject(index, "true")}
                            startIcon={<DoneIcon />}
                            sx={{
                              background: "#E2F1E6",
                              color: "#23963D",
                              fontSize: 12,
                              textTransform: "capitalize",
                            }}
                            fullWidth
                          >
                            Validated
                          </Button>
                        </Box>
                        <Button
                          onClick={() => updateSecondObject(index, "null")}
                          sx={{ fontSize: 12 }}
                          fullWidth
                        >
                          Edit
                        </Button>
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    {i?.showWidget && (
                      <Typography sx={{ fontSize: "14px", mb: 3 }}>
                        {i?.name}
                      </Typography>
                    )}

                    {i?.showWidget && (
                      <Box display="flex" flexDirection="row" gap={1}>
                        {nocType[index]?.types === "false" && (
                          <DropDown
                            value={verifyNocDocument[index]["documentReason"]}
                            name={verifyNocDocument[index]["documentReason"]}
                            onChange={(e) =>
                              handleNocReason(
                                e?.target?.value,
                                "documentReason",
                                index,
                                i?.widgetType,
                                false,
                              )
                            }
                            data={
                              partnerNoc?.data?.nocWidgets?.nocInvalidReason
                            }
                            placeholder="Select Reason"
                          />
                        )}
                        <Box>
                          <Button
                            startIcon={<DoneIcon />}
                            sx={{
                              background: "#F9E5E5",
                              color: "#DF0000",
                              fontSize: 12,
                              mt: 0.6,
                              minWidth: "unset",
                              textTransform: "capitalize",
                            }}
                            fullWidth
                          >
                            Invalidated
                          </Button>
                        </Box>
                        <Button
                          onClick={() => updateSecondObject(index, "null")}
                          sx={{ fontSize: 12 }}
                          fullWidth
                        >
                          Edit
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {/* {partnerNoc?.data?.nocWidgets?.showWidget && 
        <NocDetails partnerNoc={partnerNoc} nocType={nocType} verifyNocDocument={setVerifyDocument} setNocType={setNocType} setVerifyNocDocument={setVerifyDocument}  />
      } */}

      {/* Submit Button  */}

      <Divider />
      {isBank === "Payment Pending" ? (
        <Box mt={1.5} display="flex" justifyContent="end" alignItems="center">
          <Button
            variant="contained"
            style={{
              background: bankReason || bank ? "#0691FC" : "",
              borderRadius: 8,
            }}
            onClick={handlePaymentSubmit}
            disabled={bankReason || bank ? false : true}
          >
            Submit
          </Button>
        </Box>
      ) : (
        <Box mt={1.5} display="flex" justifyContent="end" alignItems="center">
          <Button
            variant="contained"
            style={{
              background: isButtonDisabled ? "" : "#0691FC",
              borderRadius: 8,
            }}
            onClick={handleNocSubmit}
            disabled={isButtonDisabled}
          >
            Submit
          </Button>
        </Box>
      )}
    </Box>
  );
}
