import axios from "../api/axios";
import {
  partnerListURL,
  partnerNOCURL,
  partnerPaymentURL,
  verifyCheckURL,
  verifyPartnerDOCURL,
} from "../api";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";

export const getPartnerListAPI =
  (search, status, subStatus) => async (dispatch) => {
    try {
      let response = await axios.get(partnerListURL, {
        params: {
          search,
          status,
          subStatus,
        },
      });
      if (response.status === 200) {
        dispatch({ type: "GET_PARTNER_LIST", data: response.data });
      }
    } catch (error) {
      throw error.message;
    }
  };

export const getPartnerPaymentAPI = (partnerId) => async (dispatch) => {
  try {
    let response = await axios.get(partnerPaymentURL, {
      params: {
        partnerId,
      },
    });
    if (response.status === 200) {
      dispatch({
        type: "GET_PARTNER_PAYMENT_VERIFICATION",
        data: response.data,
      });
    }
  } catch (error) {
    throw error.message;
  }
};

export const getPartnerNOCAPI = (partnerId) => async (dispatch) => {
  try {
    let response = await axios.get(partnerNOCURL, {
      params: {
        partnerId,
      },
    });
    if (response.status === 200) {
      dispatch({ type: "GET_PARTNER_NOC_VERIFICATION", data: response.data });
    }
  } catch (error) {
    throw error.message;
  }
};

export const verifyPartnerDocAPI =
  (data, setOpenModal, setIsVerify) => async (dispatch) => {
    try {
      let response = await axios.post(verifyPartnerDOCURL, data);
      if (response.status === 200) {
        dispatch(getPartnerListAPI());
        // message.success(response?.data?.msg)
        setOpenModal(true);
        setIsVerify("true");
      } else if (response.status === 400) {
        // setOpenModal(true)
        // setIsVerify("false")
      }
    } catch (error) {
      // setOpenModal(true)
      // setIsVerify("false")
      message.error("Something went wrong.");
    }
  };

export const verifyCheckAPI =
  (data, setOpenModal, setIsVerify) => async (dispatch) => {
    try {
      let response = await axios.post(verifyCheckURL, data);
      if (response.status === 200) {
        dispatch(getPartnerListAPI());
        // message.success(response?.data?.msg)
        setOpenModal(true);
        setIsVerify("true");
      } else if (response.status === 400) {
        // setOpenModal(true)
        // setIsVerify("false")
      }
    } catch (error) {
      // setOpenModal(true)
      // setIsVerify("false")
      message.error("Something went wrong.");
    }
  };
