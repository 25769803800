import React, { Fragment } from "react";
import QuoteShared from "./QuoteShared";
import KycDocuments from "./KycDocuments";
import { quoteRequestStateList } from "../../helpers/enums";
import Heading from "../../components/generic/Heading/Heading";
import { Box, Typography } from "@mui/material";
import LottiePlayer from "../../components/Lottie/LottiePlayer";
import Pending from "../../assets/Lottie/pendingClock.json";
const QuoteSharedModule = ({ quoteState }) => {
  return (
    <Fragment>
      <QuoteShared />
      {quoteState === quoteRequestStateList.FILL_KYC_DOCUMENT && (
        <div>
          <Heading text="Customer KYC documents" type="withborder" />
          <Box
            sx={{
              gap: 2,
              backgroundColor: "#fff",
              alignItems: "center",
              borderRadius: "16px",
              padding: "24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                background: "#FCEFE8",
                flexDirection: "column",
                gap: "24px",
                borderRadius: "16px",
                padding: "24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",

                  borderRadius: 90,
                }}
              >
                {/* <img src={RightGif} alt="" /> */}
                <LottiePlayer
                  width={"200px"}
                  height={"200px"}
                  image={Pending}
                />
              </Box>
              <Typography
                sx={{
                  fontWeight: "400",
                  textAlign: "center",
                  fontSize: 16,
                  color: "#000",
                }}
                variant="h4"
              >
                {" "}
                Please wait while partner uploads ths KYC documents
              </Typography>
            </Box>
          </Box>
        </div>
      )}
      {quoteState === quoteRequestStateList.VERIFY_KYC_DOCUMENT && (
        <KycDocuments />
      )}
    </Fragment>
  );
};

export default QuoteSharedModule;
