import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "../index.css";

export default function BookingTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "1061px",
        backgroundColor: "white",
        height: "70px",
        borderRadius: "12px",
    
      }}
    >
      <Tabs value={value} onChange={handleChange} centered>
        <Tab
          sx={{ width: "354px", height: "70px", border: "0px solid transparent",
            borderWidth: "0px 0.5px 0px 0px",
            borderColor: "gray",}}
          label="Verification Pending"
        />
        <Tab sx={{ width: "354px", height: "70px",border: "0px solid transparent",
        borderWidth: "0px 0.5px 0px 0px",
        borderColor: "gray",  }} label="Rejected" />
        <Tab sx={{ width: "354px", height: "70px",border: "0px solid transparent",
        borderWidth: "0px 0.5px 0px 0px",
        borderColor: "gray", }} label="Disbursed" />
      </Tabs>
    </Box>
  );
}
