const inititalState = {
  mappingRequestsList: [],
  mappingVerificationDetails: {},
  isLoadingRequests: false,
  isLoadingDetails: false,
  bookingOptions: [],
  vehicleOptions: [],
  headerOptions: {},
  docs: [],
  rejectReasons: {},
};

export default function mappingRequestsReducer(state = inititalState, action) {
  switch (action.type) {
    case "GET_MAPPING_REQUESTS_LIST":
      return {
        ...state,
        mappingRequestsList: action.data,
        isLoadingRequests: false,
      };
    case "GET_MAPPING_VERIFICATION_DETAILS":
      return {
        ...state,
        mappingVerificationDetails: {
          documentData: action.data.data,
          extraData: action.data.extraData,
          mappingId: action.data.mappingId,
        },
        isLoadingDetails: false,
      };

    case "SET_MAPPING_REQUESTS_LOADING":
      return {
        ...state,
        isLoadingRequests: action.data,
      };
    case "SET_MAPPING_DETAILS_LOADING":
      return {
        ...state,
        isLoadingDetails: action.data,
      };
    case "SET_MAPPING_OPTIONS":
      return {
        ...state,
        bookingOptions: action.data.bookingOptions,
        vehicleOptions: action.data.vehicleOptions,
        headerOptions: action.data.header,
        docs: action.data.docs,
        isLoadingDetails: false,
      };

    default:
      return state;
  }
}
