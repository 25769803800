import { message } from "../../components/generic/SnakeAlert/SnakeAlert";
import { getSalesPartnerURL } from "../api";
import axios from "../api/axios";

// Action Types
export const GET_PARTNER_LIST_SUCCESS = "GET_PARTNER_LIST_SUCCESS";
export const GET_PARTNER_LIST_FAILURE = "GET_PARTNER_LIST_FAILURE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

// Action Creator to fetch partner list
export const salesPartnerList = (search) => async (dispatch) => {
  try {
    const response = await axios.get(getSalesPartnerURL, {
      params: search,
    });

    if (response.status === 200) {
      const { partnerList, pagination, partnerBucket } = response.data.data;

      dispatch({
        type: GET_PARTNER_LIST_SUCCESS,
        payload: {
          partnerList,
          pagination,
          partnerBucket,
        },
      });
    } else {
      throw new Error(response.data.msg || "Failed to fetch partner list");
    }
  } catch (error) {
    dispatch({
      type: GET_PARTNER_LIST_FAILURE,
      error: error.message,
    });
    message.error("Error fetching partner list: " + error.message);
  }
};
