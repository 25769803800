import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Checkbox, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";

export default function DropDown({
  value,
  onChange,
  placeholder,
  data,
  width,
  height,
  name,
  disabled, // Add disabled prop
  renderOption, // Add a custom option rendering function
  multiple = false,
  ...rest
}) {
  return (
    <Select
      name={name}
      value={value}
      onChange={onChange}
      // clear button
      endAdornment={
        !(!value || (value && value.length === 0))  && !disabled ? (
          <IconButton
            onClick={() => onChange({ target: { value: "" } })}
            size="small"
          >
            <Clear />
          </IconButton>
        ) : null
      }
      // value is empty or empty array, icon is ExpandMoreIcon else Clear
      IconComponent={!value || (value && value.length === 0) ? ExpandMoreIcon : null}
      displayEmpty
      disabled={disabled} // Pass the disabled prop to the Select component
      sx={{
        boxShadow: "none",
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        //error border
        "&.Mui-error": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#f44336",
            borderWidth: 1,
          },
        },
        height: height ? height : 44,
        fontSize: 14,
        border: "1px solid #8B8B8B",
        width: width ? width : "100%",
        background: "#fff",
        // borderRadius: var(--border-radius-8),
        borderRadius: 2,
      }}
      multiple={multiple}

      renderValue={(selected) => {
        if (!selected || selected.length === 0) {
          return <span>{placeholder}</span>;
        }

        if (multiple ) {
          if (selected.length === 1) {
            return selected[0];
          }
          if (selected.length === data.length) {
            return "All";
          }
          if (selected.length > 1) {
          return `${selected[0]} + ${selected.length - 1} more`;
          }
        } 
        return selected;
      }}
      
      {...rest}
    >
      {data?.map((item, idx) => (
        <MenuItem sx={{ fontSize: 14 }} key={item + "-" + idx} value={item}>
          {
            multiple && (
              <Checkbox checked={value?.indexOf(item) > -1} />
            )
          }
          {/* Use the custom renderOption function if provided, else display the item */}
          {renderOption ? renderOption(item) : item}
        </MenuItem>
      ))}
    </Select>
  );
}
