import { Stack } from "@mui/material";
import Search from "../../components/generic/Search/Search";
import Header from "../../components/generic/Header/Header";
import DropDown from "../../components/generic/DropDown/DropDown";
import TableComponent from "../../components/Table/QuoteTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuoteListAPI } from "../../store/action/quote";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
const QuoteStateList = [
  {
    label: "No sub status",
    value: "",
  },
  {
    label: "Docs Under Verification",
    value: "DOCUMENT_VERIFICATION_STARTED",
  },
  {
    label: "Policy Rejected, Case Closed",
    value: "POLICY_MAPPING_REJECTED",
  },
  {
    label: "Document Verification Pending",
    value: "Quote Requested",
  },
  {
    label: "Submit Documents",
    value: "DOCUMENT_REUPLOAD",
  },
  {
    label: "Quote Pending",
    value: "SHARE_QUOTE",
  },
  {
    label: "Quote Shared",
    value: "QUOTE_SHARED",
  },
  {
    label: "Quote Change Requested",
    value: "QUOTE_CHANGE_REQUEST",
  },
  {
    label: "Insurer Approval Pending",
    value: "QUOTE_ACCEPTED_WITH_INSPECTION_SEND_FOR_APPROVAL",
  },
  {
    label: "Fill Customer Details",
    value: "FILL_CUSTOMER_DETAILS",
  },
  {
    label: "Inspection In Progress",
    value: "INSPECTION_PORTAL_REQUEST_RAISE",
  },
  {
    label: "Inspection Details Shared",
    value: "INSPECTION_DETAILS_SHARED",
  },
  {
    label: "Quote Approval Pending at Insurer",
    value: "QUOTE_ACCEPTED_SEND_FOR_APPROVAL",
  },
  {
    label: "Payment Link Pending",
    value: "PAYMENT_LINK_PENDING",
  },
  {
    label: "Payment Link Shared",
    value: "PAYMENT_LINK_SHARED",
  },
  {
    label: "Verify Payment Details",
    value: "PAYMENT_PROOF_TAKEN",
  },
  {
    label: "Payment Details Approved",
    value: "PAYMENT_DETAILS_APPROVED",
  },
  {
    label: "Case Closed",
    value: "CASE_CLOSED",
  },
];

export default function QuoteRequest() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.quote.quoteList);
  // let token = window.localStorage.getItem("token");
  const [status, setStatus] = useState(null);
  const [subStatus, setSubStatus] = useState("");
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  // const isLoading = useSelector((state) => state.quote.quoteList);
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    if (data?.data) {
      setSearchResults(data.data);
    }
  }, [data?.data]);

  useEffect(() => {
    dispatch(getQuoteListAPI());
  }, []);
  const handleSearch = (query) => {
    setSearch(query);
  };

  const handleDateChange = (date) => {
    if (date && date.length > 0) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };

  useEffect(() => {
    if (data?.data && data?.data?.length > 0) {
      let filteredResults = data.data;
      if (status && status.value != "") {
        filteredResults = filteredResults?.filter((item) => {
          return item?.quoteState === status.value;
        });
      }
      if (subStatus) {
        filteredResults = filteredResults?.filter((item) => {
          return item?.productType === subStatus;
        });
      }

      if (search) {
        filteredResults = filteredResults?.filter((item) => {
          const lowerCaseQuery = search.toLowerCase();
          const lowerCasePartnerName = item?.partnerName?.toLowerCase();
          const lowerCaseRegNo = item?.registrationNumber?.toLowerCase();
          const lowerCaseQuoteId = item?.quoteId?.toLowerCase();
          const lowerCasePartnerId = item?.partnerId?.toLowerCase();

          return (
            lowerCasePartnerName?.includes(lowerCaseQuery) ||
            lowerCaseRegNo?.includes(lowerCaseQuery) ||
            lowerCasePartnerId?.includes(lowerCaseQuery) ||
            lowerCaseQuoteId?.includes(lowerCaseQuery)
          );
        });
      }
      if (filterByDate.startDate && filterByDate.endDate) {
        filteredResults = filteredResults?.filter((item) => {
          const startDate = new Date(filterByDate.startDate).setHours(
            0,
            0,
            0,
            0,
          );
          const endDate = new Date(filterByDate.endDate).setHours(
            23,
            59,
            59,
            999,
          );
          const itemDate = new Date(item?.timestamp).getTime();
          return itemDate >= startDate && itemDate <= endDate;
        });
      }
      setSearchResults(filteredResults || []);
    }
  }, [status, subStatus, search, filterByDate, data]);

  return (
    <main className="main">
      <Header title="Quote Requests" />
      <div className="list-wrapper">
        <header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Search
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={"Partner name, Partner code, Ticket ID"}
            />
            <Stack direction="row" spacing={2} alignItems="center">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="label">Filters :</label>
              <DropDown
                value={subStatus}
                onChange={(e) =>
                  setSubStatus(
                    e.target.value === "No Sub Status" ? "" : e.target.value,
                  )
                }
                width={145}
                placeholder="Product"
                data={[
                  "CAR",
                  "TW",
                  "GCV",
                  "PCV",
                  "BUS",
                  "MISC_D",
                  "No Sub Status",
                ]}
              />
              <DropDown
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                width={175}
                placeholder={"Select Status"}
                data={QuoteStateList}
                renderOption={(option) => option.label}
                renderValue={(value) =>
                  value && value.value != "" ? value.label : "Select Status"
                }
              />
              <div className="date-picker">
                <Flatpickr
                  placeholder="Date Range"
                  options={{
                    dateFormat: "d-m-Y",
                    maxDate: new Date(),
                    mode: "range",
                  }}
                  className="flat_picker_date"
                  style={{ width: "200px", height: "41px" }}
                  value={
                    filterByDate.startDate && filterByDate.endDate
                      ? [filterByDate.startDate, filterByDate.endDate]
                      : ""
                  }
                  onChange={handleDateChange}
                />
              </div>
            </Stack>
          </Stack>
        </header>
        <TableComponent rows={searchResults} />
      </div>
    </main>
  );
}
