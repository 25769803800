import React from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";

// Function to provide accessibility props to tabs
function a11yProps(index) {
  return {
    id: `custom-tab-${index}`,
    "aria-controls": `custom-tabpanel-${index}`,
  };
}

// CustomTabing component
const CustomTabing = ({ value, tabList, handleChange }) => {
  return (
    <Box
      sx={{
        width: "100%",
        margin: "20px auto",
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={(event, newValue) => {
            handleChange(newValue);
          }}
          aria-label="custom tabs example"
          sx={{ borderRadius: "10px" }}
          variant="fullWidth"
        >
          {tabList.map((item, index) => (
            <Tab
              key={index}
              {...a11yProps(index)}
              className="tab-set"
              style={{
                color: "#0691FC",
                padding: "13px 5px",
                width: "100%",
                background:
                  value === item.bucket
                    ? "linear-gradient(7.64deg, #D4ECFF 6.59%, rgba(255, 255, 255, 0) 94.82%)"
                    : "white",
              }}
              label={
                <Box sx={{ height: "60px" }}>
                  <Typography
                    variant="h6"
                    style={{
                      color: value === item.bucket ? "#0691FC" : "black",
                    }}
                  >
                    {item.count}
                  </Typography>
                  <Typography
                    variant=""
                    style={{
                      fontSize: "12px",
                      color: "rgba(102, 102, 102, 1)",
                      fontWeight: "400",
                    }}
                  >
                    {item.bucketName}
                  </Typography>
                </Box>
              }
              value={item.bucket}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  );
};

export default CustomTabing;
