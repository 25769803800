import { Box, CircularProgress, Stack } from "@mui/material";
import Search from "../../components/generic/Search/Search";
import Header from "../../components/generic/Header/Header";
import DropDown from "../../components/generic/DropDown/DropDown";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import CustomTabing from "../../components/generic/Tabing/CustomTabing";
import SalesMappingTable from "../../components/Table/SalesMappingTable";
import {
  GET_UPLOAD_DOCS_LIST,
  getRequiredDocsData,
  getSalesMappingListAPI,
  RESET_SALES_MAPPING,
} from "../../store/action/salesMapping";
import { debounce, formatDate } from "../../utils/utils";
import { fetchParterIdsList } from "../../store/action/auth";
import Paginations from "../../components/generic/Pagination/Pagination";
import MappingUploadDoc from "../../components/modals/MappingUploadDoc";
export default function SalesMappingRequest() {
  const dispatch = useDispatch();
  const { mappingList, isLoading, mappingBucket, pagination } = useSelector(
    (state) => state.salesMapping,
  );
  const { partnerIds } = useSelector((state) => state.auth);

  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(pagination?.curPage || 1);
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [uploadDocModal, setUploadDocModal] = useState(false);

  const handleDateChange = (date) => {
    if (date && date.length > 0) {
      setFilterByDate({ startDate: date[0], endDate: date[1] });
    }

    if (date && date.length === 0) {
      setFilterByDate({ startDate: "", endDate: "" });
    }
  };
  const handlePageChange = (event, page) => {
    if (page > pagination?.totalPage) {
      return;
    }
    setCurrentPage(page);
  };

  //add debounced search

  const handleSearch = (query) => {
    setSearch(query);
  };
  const [selectedPartner, setSelectedPartner] = useState("");
  const [currentMappingId, setCurrentMappingId] = useState("");
  const [currentBucket, setCurrentBucket] = useState();

  const handleTabChange = (newValue) => {
    setCurrentBucket(newValue);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (!partnerIds) {
      dispatch(fetchParterIdsList());
    }
  }, [partnerIds]);

  const debouncedGetSalesApi = useCallback(
    debounce((searchParams) => {
      dispatch(getSalesMappingListAPI(searchParams));
    }, 500),
    [dispatch],
  );

  useEffect(() => {
    let searchParams = {};
    if (selectedPartner) {
      searchParams["partnerId"] = selectedPartner;
    }
    if (search) {
      if (search.length < 3) return;
      searchParams["searchQuery"] = search;
    }
    if (filterByDate.startDate && filterByDate.endDate) {
      searchParams["startDate"] = formatDate(filterByDate.startDate);
      searchParams["endDate"] = formatDate(filterByDate.endDate);
    }
    if (currentPage) {
      searchParams["curPage"] = currentPage;
    }
    if (currentBucket) {
      searchParams["curBucket"] = currentBucket;
    }
    // debouncedDispatch(dispatch(getSalesMappingListAPI(searchParams)));
    debouncedGetSalesApi(searchParams);
  }, [search, selectedPartner, filterByDate, currentPage, currentBucket]);

  const handleToToggleModal = (e, event, mappingId) => {
    e.stopPropagation(); // This prevents the parent's click event from firing
    dispatch(getRequiredDocsData(mappingId));
    setCurrentMappingId(mappingId);
    if (event === "upoad_doc") setUploadDocModal(true);
  };
  const handleMappingUploadDocClose = () => {
    setUploadDocModal(false);
    setCurrentMappingId("");
    dispatch({ type: GET_UPLOAD_DOCS_LIST, data: [] });
  };
  useEffect(() => {
    return () => {
      dispatch({ type: RESET_SALES_MAPPING });
    };
  }, []);

  return (
    <main className="main">
      <Header title="Offline Mapping" />
      <div className="">
        <header>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Search
              value={search}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <Stack direction="row" spacing={2} alignItems="center">
              {/*eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label className="label">Filters :</label>
              <DropDown
                value={selectedPartner}
                onChange={(e) => setSelectedPartner(e.target.value)}
                width={145}
                placeholder="Partners"
                data={partnerIds || []}
              />
              <div className="date-picker">
                <Flatpickr
                  placeholder="Date Range"
                  options={{
                    dateFormat: "d-m-Y",
                    maxDate: new Date(),
                    mode: "range",
                  }}
                  className="flat_picker_date"
                  style={{ width: "200px", height: "41px" }}
                  value={
                    filterByDate.startDate && filterByDate.endDate
                      ? [filterByDate.startDate, filterByDate.endDate]
                      : ""
                  }
                  onChange={handleDateChange}
                />
              </div>
            </Stack>
          </Stack>
          <CustomTabing
            tabList={mappingBucket?.bucketList || []}
            value={mappingBucket?.curBucket}
            handleChange={handleTabChange}
          />
        </header>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <SalesMappingTable
              rows={mappingList}
              handleToToggleModal={handleToToggleModal}
            />
          </Box>
        )}
      </div>
      <Box style={{ marginTop: "20px" }}>
        <Paginations
          currentPage={currentPage}
          totalPage={pagination?.totalPage || 1}
          handlePageChange={handlePageChange}
        />
      </Box>
      <MappingUploadDoc
        open={uploadDocModal}
        handleClose={handleMappingUploadDocClose}
        mappingId={currentMappingId}
      />
    </main>
  );
}
