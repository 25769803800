import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CustomSwitch } from "../../components/generic/CustomSwitch";
import { ReactComponent as AttachIcon } from "../../assets/icons/attachIcon.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/bin.svg";
import Addons from "../../components/QuoteComponent/Addons";
import { InsurerLogoMap } from "../../helpers/InsurerLogoMap";

const RequestQuoteComponent = ({
  key,
  data,
  idx,
  updateNewData,
  files,
  setFiles,
}) => {
  const [isQuoteAvailable, setIsQuoteAvailable] = useState(true);
  const [inspectionRequired, setInspectionRequired] = useState(false);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [file, setfile] = useState();

  const handleFileChange = (event, idx) => {
    const newFiles = [...files];
    newFiles[idx] = event.target.files[0];
    setFiles(newFiles);
    setfile(event.target.files?.[0]);
  };

  const removeFileFromSelectedFiles = (fileToRemove) => {
    const updatedFiles = files?.filter((file) => file !== fileToRemove);
    setFiles(updatedFiles);
    setfile(null);
  };

  useEffect(() => {
    updateNewData(idx, {
      quoteRequestId: data?.quoteRequestId,
      isQuoteAvailable: isQuoteAvailable,
      inspectionRequired: inspectionRequired,
    });
  }, [isQuoteAvailable, inspectionRequired]);

  const [nocType, setNocType] = useState([
    { types: "null" },
    { types: "null" },
    { types: "null" },
    { types: "null" },
    { types: "null" },
  ]);
  // Function to update the second object in the array
  const updateSecondObject = (index, val) => {
    // Create a new array with the same objects
    const updatedNocType = [...nocType];

    // Update the second object at index 1 (since arrays are zero-based)
    updatedNocType[index] = { types: val };

    // Set the updated array as the new state
    setNocType(updatedNocType);
  };

  return (
    <div
      key={key}
      style={{
        display: "flex",

        borderRadius: "8px",
        boxShadow: "0px 2px 4px 0px #0000001A",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          padding: "8px 20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
          }}
        >
         {
          data?.insurerId && InsurerLogoMap[data?.insurerId] ?
          <img src={InsurerLogoMap[data?.insurerId]?.Logo} alt="insurer" />
          :
         <p>{data?.insurerName}</p>
         }
          {isQuoteAvailable && (
            <>
              <CustomSwitch
                {...label}
                value={inspectionRequired}
                onChange={(e) => setInspectionRequired(e.target.checked)}
              />
              <h3>Inspection</h3>
            </>
          )}
        </div>
        {isQuoteAvailable ? (
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            {file ? (
              <>
                <AttachIcon />
                <div style={{ display: "flex", width: "200px" }}>
                  <Typography
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    variant="p"
                  >
                    {file?.name}
                  </Typography>
                </div>
                <RemoveIcon
                  sx={{ color: "red" }}
                  onClick={() => removeFileFromSelectedFiles(file)}
                  cursor={"pointer"}
                />
              </>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <img src="/uploadIcon..svg" alt="file" />
                  <h2
                    style={{
                      color: "#0691FC",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    <input
                      type="file"
                      onChange={(e) => handleFileChange(e, idx)}
                      name="uploadfile"
                      id={`${data?.quoteRequestId}`}
                      style={{ display: "none" }}
                    />
                    <label htmlFor={`${data?.quoteRequestId}`}>Upload</label>
                  </h2>
                </div>
                <div
                  role="button"
                  tabIndex={0}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8x",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    updateSecondObject(idx, "null");
                    setIsQuoteAvailable(false);
                  }}
                >
                  <Close />
                  <h2
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#808080",
                    }}
                  >
                    Unavailable
                  </h2>
                </div>
              </>
            )}
          </div>
        ) : (
          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            <div>
              <p
                style={{
                  fontSize: 10,
                  fontWeight: "500",
                  color: "#666666",
                  background: "#E6E6E6",
                  padding: "4px 8px",
                  textAlign: "center",
                  borderRadius: "8px",
                  width: "120px",
                }}
              >
                Quote Unavilable
              </p>
            </div>
            <Button
              onClick={() => setIsQuoteAvailable(true)}
              sx={{ fontSize: 12 }}
              fullWidth
            >
              Edit
            </Button>
          </Box>
        )}
      </div>
      {data?.addOns?.length > 0 && <Addons addOns={data.addOns} />}
    </div>
  );
};

export default RequestQuoteComponent;
