import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import vector from "../../assets/icons/Vector.png";
import right from "../../assets/icons/Frame 427319265.png";
import { formatDate } from "../../utils/utils";

const SalesPartnerTable = ({ partnerList }) => {
  return (
    <>
      <TableContainer
        sx={{
          borderRadius: "10px ",
          height: "calc(100vh - 355px)",
          overflowY: "auto",
        }}
      >
        <Table
          sx={{
            Width: "1063px",
          }}
        >
          <TableHead
            sx={{
              backgroundColor: "#ececec",
              position: "sticky",
              top: 0,
              zIndex: 1,
            }}
          >
            <TableRow>
              <TableCell>Partner</TableCell>
              <TableCell align="right">PAN Status</TableCell>
              <TableCell align="right">Payment Status</TableCell>
              <TableCell align="right">Last Update</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "white", borderRadius: "10px" }}>
            {partnerList?.length > 0 ? (
              partnerList.map((value, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      fontWeight: "600",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "Black",
                    }}
                  >
                    {value?.partnerName}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                        gap: "4px",
                        margin: "10px,0px",
                      }}
                    >
                      {value?.hierarchy?.map((level, i) => (
                        <Typography
                          key={i}
                          sx={{
                            display: "flex",
                            justifyContent: "start",
                            gap: "3px",
                            alignItems: "center",
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "Grey/20",
                            lineHeight: "18px",
                          }}
                        >
                          {level}

                          {value.hierarchy.length > 1 &&
                            i < value.hierarchy.length - 1 && (
                              <img src={vector} alt="vector" />
                            )}
                        </Typography>
                      ))}
                    </div>
                  </TableCell>

                  <TableCell align="right">
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "27px",
                        padding: "4px 16px",
                        backgroundColor: value?.panStatus
                          ? "rgba(1, 176, 18, 1)"
                          : "rgba(255, 168, 0, 1)",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "18px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: value?.panStatus
                            ? "rgba(1, 176, 18, 1)"
                            : "rgba(255, 168, 0, 1)",
                        },
                      }}
                    >
                      {value?.panStatus && (
                        <img
                          src={right}
                          alt="icon"
                          style={{ marginRight: "2px" }}
                        />
                      )}
                      {value?.panStatus ? "Verified" : "In Review"}
                    </Button>
                  </TableCell>

                  <TableCell align="right">
                    <Button
                      variant="contained"
                      sx={{
                        borderRadius: "27px",
                        padding: "4px 16px",
                        backgroundColor: value?.paymentStatus
                          ? "rgba(1, 176, 18, 1)"
                          : "rgba(255, 168, 0, 1)",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "18px",
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: value?.paymentStatus
                            ? "rgba(1, 176, 18, 1)"
                            : "rgba(255, 168, 0, 1)",
                        },
                      }}
                    >
                      {value?.paymentStatus && (
                        <img
                          src={right}
                          alt="icon"
                          style={{ marginRight: "2px" }}
                        />
                      )}

                      {value?.paymentStatus ? "Verified" : "In Review "}
                    </Button>
                  </TableCell>

                  <TableCell
                    align="right"
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      lineHeight: "18px",
                    }}
                  >
                    {" "}
                    {value &&
                      formatDate(value.lastUpdated, "DD-MM-YYYY, hh:mm a")}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No Partners Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SalesPartnerTable;
