import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ component: Component, featureName, ...rest }) => {
  const { permissions, loading } = useSelector((state) => state.auth);
  // const [loading, setLoading] = useState(true)?
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchPermissions = async () => {
  //     await dispatch(validateTokenAndFetchPermissions(navigate));
  //     setLoading(false);
  //   };
  //   if (!permissions) {
  //     fetchPermissions();
  //   } else {
  //     setLoading(false);
  //   }
  // }, [permissions]);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (permissions?.[featureName]?.VIEW) {
    return <Component {...rest} permissions={permissions[featureName]} />;
  }

  if (permissions) return <Navigate to="/access-denied" />;
};

export default ProtectedRoute;
