import React, { useEffect, useState } from "react";
import "./index.css";
import Img from "../../../assets/icons/Logo.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { navs } from "../../../assets/constant";
import { useNavigate, useLocation } from "react-router-dom";
import SideBarVerifyDocs from "./SideBarVerifyDocs";
import { useSelector } from "react-redux";

const SideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [activeSubLink, setActiveSubLink] = useState(location.pathname);
  //USING FOR VERIFYDOCS
  const url = window.location.href;
  const pathSegments = url.split("/");
  const endpointSegment = pathSegments.slice(3)?.[1];
  const permissions = useSelector((state) => state?.auth?.permissions) || {};

  const filterNavsByPermissions = (navs, permissions) => {
    // Ensure permissions is an array
    if (
      !permissions ||
      typeof permissions !== "object" ||
      Object.keys(permissions).length === 0
    ) {
      return [];
    }

    return navs.filter((navItem) => {
      let hasPermissionFlag = false;

      // Check for main nav item's permission
      if (permissions[navItem.featureName]?.VIEW) {
        hasPermissionFlag = true;
      }

      // Check for subNav permissions
      if (navItem.subNav?.length > 0) {
        // Filter subNav items based on permissions
        navItem.subNav = navItem.subNav.filter((subNavItem) => {
          return permissions[subNavItem.featureName]?.VIEW;
        });

        if (navItem.subNav.length) {
          hasPermissionFlag = true;
        }
      }

      return hasPermissionFlag;
    });
  };

  const filteredNavs = Array.isArray(navs)
    ? filterNavsByPermissions(navs, permissions)
    : [];

  useEffect(() => {
    const currentPath = location.pathname.split("/");

    // Find active main feature
    const activeMain = filteredNavs.find((navItem) => {
      const navPath = navItem.link; // navItem.link is already like "sales/quote-request"
      const currentMainPath = currentPath.slice(2).join("/"); // Remove "app" and get the remaining path

      return currentMainPath.startsWith(navPath); // Compare without the "app" prefix
    });

    if (activeMain) {
      setActiveLink(activeMain.featureName);

      // Find active sub feature if exists
      const activeSub = activeMain.subNav?.find((subNavItem) =>
        currentPath.includes(subNavItem.link),
      );

      if (activeSub) {
        setActiveSubLink(activeSub.featureName);
      } else {
        setActiveSubLink(null);
      }
    } else {
      setActiveLink(null);
      setActiveSubLink(null);
    }
  }, [location.pathname, filteredNavs]);

  return (
    <aside
      className={
        endpointSegment === "quote-verifyDocs" ? "sidebarQuote" : "sidebar"
      }
    >
      <div className="logo" style={{ cursor: "pointer", padding: "8px 8px" }}>
        <img src={Img} alt="" onClick={() => navigate("/")} />
      </div>
      <nav className="navbar">
        {endpointSegment === "quote-verifyDocs" ? (
          <SideBarVerifyDocs />
        ) : (
          <ul>
            {filteredNavs.map((item, idx) => {
              const isSubNav = item?.subNav?.length > 0 ? "subNav" : "";
              return (
                <li key={`navItem${idx}`}>
                  <div
                    role="link"
                    className={
                      `navToggle ${isSubNav}` +
                      (activeLink === item.featureName ? " active" : "")
                    }
                    onClick={() => {
                      navigate(item.link);
                    }}
                    tabIndex={0}
                  >
                    <i className={`icon icon-${item.text}`}>{item.icon}</i>
                    {item.text}
                    {item?.subNav?.length > 0 && (
                      <i className="icon icon-chevron">
                        <ExpandMoreIcon />
                      </i>
                    )}
                  </div>
                  {item?.subNav?.length > 0 && (
                    <ul>
                      {item.subNav.map((child, i) => (
                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`${item.link}/${child.link}`);
                          }}
                          key={`navItemChild${i}`}
                          className={
                            child.featureName === activeSubLink ? "active" : ""
                          }
                        >
                          {child.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </nav>
    </aside>
  );
};

export default SideBar;
