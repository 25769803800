import axios from "../api/axios";
import { getOptionsDataURL, getPolicyDealsURL } from "../api";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";

// Define action types
const GET_OPTIONS_DATA_REQUEST = "GET_OPTIONS_DATA_REQUEST";
const GET_OPTIONS_DATA_SUCCESS = "GET_OPTIONS_DATA_SUCCESS";
const GET_OPTIONS_DATA_FAILURE = "GET_OPTIONS_DATA_FAILURE";

const GET_POLICY_DEALS_REQUEST = "GET_POLICY_DEALS_REQUEST";
const GET_POLICY_DEALS_SUCCESS = "GET_POLICY_DEALS_SUCCESS";
const GET_POLICY_DEALS_FAILURE = "GET_POLICY_DEALS_FAILURE";

const RESET_POLICY_DEALS = "RESET_POLICY_DEALS";
const RESET_OPTIONS_DATA = "RESET_OPTIONS_DATA";

// Action creators for getting options data
export const getOptionsDataAPI = (params) => async (dispatch) => {
  dispatch({ type: GET_OPTIONS_DATA_REQUEST }); // Dispatch request action

  try {
    let response = await axios.get(getOptionsDataURL, { params });
    if (response.status === 200) {
      dispatch({ type: GET_OPTIONS_DATA_SUCCESS, data: response.data.data });
    }
  } catch (error) {
    dispatch({ type: GET_OPTIONS_DATA_FAILURE }); // Dispatch failure action
    message.error(error.response.data.data.msg);
  }
};

// Action creators for getting policy deals
export const getPolicyDealsAPI = (data) => async (dispatch) => {
  dispatch({ type: GET_POLICY_DEALS_REQUEST }); // Dispatch request action

  try {
    let response = await axios.get(getPolicyDealsURL + "?" + data);
    if (response.status === 200) {
      dispatch({ type: GET_POLICY_DEALS_SUCCESS, data: response.data.data });
    }
  } catch (error) {
    dispatch({ type: GET_POLICY_DEALS_FAILURE }); // Dispatch failure action
    message.error(error.response.data.msg);
  }
};

// Action creators for resetting data
export const resetPolicyDeals = () => ({ type: RESET_POLICY_DEALS });
export const resetOptionsData = () => ({ type: RESET_OPTIONS_DATA });