const inititalState = {
  quoteList: null,
  quoteDetails: null,
};

export default function quoteReducer(state = inititalState, action) {
  switch (action.type) {
    case "GET_QUOTE_LIST":
      return {
        ...state,
        quoteList: action.data,
      };
    case "GET_QUOTE_DETAILS":
      return {
        ...state,
        quoteDetails: action.data,
      };

    default:
      return state;
  }
}
