import axios from "../api/axios";
import {
  quoteListURL,
  quoteDetailURL,
  postCommentURL,
  quoteVerifyDocsURL,
  noQuoteAvailableURL,
  shareQuoteDetailsURL,
  quoteChangeRequestURL,
  shareQuoteApprovalURL,
  paymentLinkPendingURL,
  pendingReportURL,
  agencyDetailsURL,
  paymentLinkVerifyURL,
  uploadPolicyCopyURL,
  verifyKycPOST,
} from "../api";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";

export const getQuoteListAPI = (search) => async (dispatch) => {
  try {
    let response = await axios.get(quoteListURL, {
      params: {
        search,
      },
    });
    if (response.status === 200) {
      dispatch({ type: "GET_QUOTE_LIST", data: response.data });
    }
  } catch (error) {
    throw error.message;
  }
};

export const getQuoteDetailAPI = (quoteId) => async (dispatch) => {
  try {
    let response = await axios.get(quoteDetailURL, {
      params: {
        quoteId,
      },
    });
    if (response.status === 200) {
      dispatch({ type: "GET_QUOTE_DETAILS", data: response.data });
    }
  } catch (error) {
    throw error.message;
  }
};

export const postCommentAPI = (comment, quoteId) => async (dispatch) => {
  try {
    const data = {
      quoteId: quoteId,
      comment: comment,
    };
    let response = await axios.post(postCommentURL, data);
    if (response.status === 200) {
      dispatch(getQuoteDetailAPI(quoteId));
      message.success(response.data.msg);
    }
  } catch (error) {
    message.error(error.response.data.msg);
  }
};

export const quoteVerifyDocsAPI =
  (data, id, setOpenSuccess) => async (dispatch) => {
    try {
      let response = await axios.post(quoteVerifyDocsURL, data);
      if (response.status === 200) {
        if (response.data.documentVerified === 0) {
          dispatch(getQuoteDetailAPI(id));
        } else {
          message.success(response.data.msg);
          setOpenSuccess(true);
        }
      }
    } catch (error) {
      message.error(error.response.data.msg);
    }
  };

export const noQuoteAvailableAPI =
  (id, reason, setOpenSuccess, setDeclineData, setNoQuote1) =>
  async (dispatch) => {
    try {
      const data = { quoteId: id, quoteDeclineReason: reason };
      let response = await axios.post(noQuoteAvailableURL, data);

      if (response.status === 200) {
        message.success(response?.data?.msg);
        setOpenSuccess(true);
        setDeclineData(true);
        setNoQuote1(false);
        dispatch(getQuoteDetailAPI(id));
      }
    } catch (error) {
      setNoQuote1(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      message.error(error?.response?.data?.msg);
    }
  };

export const shareQuoteDetailsAPI = (formdata, setOpenSuccess) => async () => {
  try {
    let response = await axios.post(shareQuoteDetailsURL, formdata);
    if (response.status === 200) {
      message.success(response.data.msg);
      setOpenSuccess(true);
    }
  } catch (error) {
    message.error(error.response.data.msg);
  }
};

export const quoteChangeRequestAPI =
  (formdata, setOpenSuccess, setNoQuote) => async () => {
    try {
      let response = await axios.post(quoteChangeRequestURL, formdata);
      if (response.status === 200) {
        message.success(response.data.msg);
        setOpenSuccess(true);
        setNoQuote(false);
      }
    } catch (error) {
      message.error(error.response.data.msg);
    }
  };

export const shareQuoteApprovalAPI =
  (data, setOpenSuccess, setDeclineData) => async (dispatch) => {
    try {
      let response = await axios.post(shareQuoteApprovalURL, data);
      if (response.status === 200) {
        message.success(response.data.msg);
        setOpenSuccess(true);
        if (setDeclineData !== undefined && setDeclineData !== null)
          setDeclineData(true);
        dispatch(getQuoteDetailAPI(data.quoteRequestId));
      }
    } catch (error) {
      message.error(error.response.data.msg);
    }
  };

export const paymentLinkPendingAPI = (data, setOpenSuccess) => async () => {
  try {
    let response = await axios.post(paymentLinkPendingURL, data);
    if (response.status === 200) {
      message.success(response.data.msg);
      setOpenSuccess(true);
    }
  } catch (error) {
    message.error(error.response.data.msg);
  }
};

export const submitAgencyDetailsAPI =
  (data, setOpenSuccess) => async (dispatch) => {
    if (data.agencyEmail === null || data.agencyEmail === "") {
      message.error("Agency Email Required");
      return;
    } else {
      // write regular expression to validate email
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!data.agencyEmail.match(validRegex)) {
        message.error("Enter valid Email");
        return;
      }
    }
    if (
      data.agencyMobileNumber === null ||
      data.agencyMobileNumber.length !== 10
    ) {
      message.error("Enter valid mobile number");
      return;
    }
    if (data.inspectionId === null || data.inspectionId === "") {
      message.error("Inspection id Required");
      return;
    }
    if (data.agencyName === null || data.agencyName === "") {
      message.error("Agency Name Required");
      return;
    }

    try {
      let response = await axios.post(agencyDetailsURL, data);
      if (response.status === 200) {
        message.success(response.data.msg);
        setOpenSuccess(true);
        dispatch(getQuoteDetailAPI(data.quoteId));
      }
    } catch (error) {
      message.error(error.response.data.msg);
    }
  };

export const submitInspectionReportAPI =
  (data, setOpenSuccess, setDeclineData) => async (dispatch) => {
    try {
      if (!data.file) {
        message.error("select file");
      }
      if (data.inspectionState === "REJECTED" && !data.reason) {
        message.error("Please select the reason for rejection");
      }
      if (data.inspectionState === "") {
        message.error("Please select the status of the report");
      }
      let formData = new FormData();
      formData.append("quoteId", data.ticketId);
      formData.append("inspectionStatus", data.inspectionState);
      formData.append("quoteDeclineReason", data.reason);
      formData.append("file", data.file);

      let response = await axios.post(pendingReportURL, formData);
      if (response.status === 200) {
        message.success(response.data.msg);
        setOpenSuccess(true);
        setDeclineData(true);
        dispatch(getQuoteDetailAPI(data.ticketId));
      }
    } catch (error) {
      message.error(error.response.data.msg);
    }
  };
export const verifyPayment = (data, setOpenSuccess) => async (dispatch) => {
  try {
    let response = await axios.post(paymentLinkVerifyURL, data);
    if (response.status === 200) {
      message.success(response.data.msg);
      setOpenSuccess(true);
      dispatch(getQuoteDetailAPI(data.quoteId));
    }
  } catch (error) {
    message.error(error.response.data.msg);
  }
};
export const uploadPolicyCopy =
  (data, quoteId, setOpenSuccess) => async (dispatch) => {
    try {
      let response = await axios.post(uploadPolicyCopyURL, data);
      if (response.status === 200) {
        message.success(response.data.msg);
        setOpenSuccess(true);
        dispatch(getQuoteDetailAPI(quoteId));
      }
    } catch (error) {
      message.error(error.response.data.msg);
    }
  };
export const verifyKycDocsAPI = (data, setOpenSuccess) => async (dispatch) => {
  try {
    let response = await axios.post(verifyKycPOST, data);
    if (response.status === 200) {
      message.success(response.data.msg);
      setOpenSuccess(true);
      dispatch(getQuoteDetailAPI(data.quoteId));
    }
  } catch (error) {
    message.error(error.response.data.msg);
  }
};
