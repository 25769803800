import axios from "../api/axios";
import {
  getMapPolicyFieldsURL,
  getMappingListURL,
  getMappingVerificationDetails,
  mapPolicyPOSTURL,
  mapPolicyRejectURL,
  mappingRequestVerifyDocsURL,
} from "../api";
import { message } from "../../components/generic/SnakeAlert/SnakeAlert";

export const getMappingListAPI = (search) => async (dispatch) => {
  try {
    dispatch({ type: "SET_MAPPING_REQUESTS_LOADING", data: true });
    let response = await axios.get(getMappingListURL, {
      params: {
        search,
      },
    });
    if (
      response.status === 200 &&
      response.data &&
      response.data.data &&
      response.data.data.length > 0
    ) {
      dispatch({ type: "GET_MAPPING_REQUESTS_LIST", data: response.data.data });
    }
  } catch (error) {
    dispatch({ type: "SET_MAPPING_REQUESTS_LOADING", data: false });
    throw error.message;
  }
};
export const getMappingVerificationDetailsAPI =
  (mappingId) => async (dispatch) => {
    try {
      dispatch({ type: "SET_MAPPING_DETAILS_LOADING", data: true });
      let response = await axios.get(getMappingVerificationDetails, {
        params: {
          mappingId: mappingId,
        },
      });
      if (
        response.status === 200 &&
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        dispatch({
          type: "GET_MAPPING_VERIFICATION_DETAILS",
          data: { ...response.data, mappingId },
        });
      }
    } catch (error) {
      dispatch({ type: "SET_MAPPING_DETAILS_LOADING", data: false });
      throw error.message;
    }
  };
export const MappingRequestVerifyDocsAPI =
  (data, setOpenSuccess) => async (dispatch) => {
    try {
      let response = await axios.post(mappingRequestVerifyDocsURL, data);
      if (response.status === 200) {
        dispatch(getMappingListAPI());
        message.success(response.data.msg);
        setOpenSuccess(true);
      }
    } catch (error) {
      message.error(error.response.data.msg);
    }
  };
export const getMappingPolicyOptionsAPI = (mappindId) => async (dispatch) => {
  try {
    dispatch({ type: "SET_MAPPING_DETAILS_LOADING", data: true });
    let response = await axios.get(getMapPolicyFieldsURL, {
      params: {
        mappingId: mappindId,
      },
    });
    if (response.status === 200 && response.data) {
      dispatch({
        type: "SET_MAPPING_OPTIONS",
        data: {
          bookingOptions: response.data.bookingOptions || [],
          vehicleOptions: response.data.vehicleOptions || [],
          header: response.data.header || {},
          docs: response.data.documents || [],
        },
      });
    }
  } catch (error) {
    dispatch({ type: "SET_MAPPING_DETAILS_LOADING", data: false });
    throw error.message;
  }
};
export const mapPolicyCopyAPI = (data, setOpenSuccess) => async (dispatch) => {
  try {
    let response = await axios.post(mapPolicyPOSTURL, data);
    if (response.status === 200) {
      dispatch(getMappingListAPI());
      message.success(response.data.msg);
      setOpenSuccess(true);
    }
  } catch (error) {
    message.error(error.response.data.msg);
  }
};
export const mapPolicyRejectAPI =
  (data, setOpenSuccess) => async (dispatch) => {
    try {
      let response = await axios.post(mapPolicyRejectURL, data);
      if (response.status === 200) {
        dispatch(getMappingListAPI());
        message.success(response.data.msg);
        setOpenSuccess(true);
      }
    } catch (error) {
      message.error(error.response.data.msg);
    }
  };
