import React, { useEffect, useState } from "react";
import Heading from "../../components/generic/Heading/Heading";
import { Button } from "@mui/material";
import SuccessModal from "../../components/generic/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  shareQuoteApprovalAPI,
  submitAgencyDetailsAPI,
} from "../../store/action/quote";
import DialogBox from "../../components/generic/DialogBox";
import CutomeModalNoQuote from "../../components/Form/CutomeModalNoQuote";
import { getQuoteDetailAPI } from "../../store/action/quote";
import QuoteCardBox from "../../components/QuoteComponent/QuoteCardBox";
const AgencyDetailsSubmit = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [quoteId, setQuoteId] = useState("");
  const data1 = useSelector((state) => state.quote.quoteDetails);
  const quoteState = data1?.data?.quoteState;
  const [confirm, setConfirm] = useState(false);

  const initialInputState = [
    { placeHolder: "Inspection Id", value: "", key: "inspectionId" },
    { placeHolder: "Agency Name", value: "", key: "agencyName" },
    { placeHolder: "Agency Email", value: "", key: "agencyEmail" },
    {
      placeHolder: "Agency Mobile Number",
      value: "",
      key: "agencyMobileNumber",
    },
  ];
  useEffect(() => {
    if (data1?.data?.header?.tickeId) {
      setQuoteId(data1?.data?.header?.tickeId);
    }
  }, [data1]);

  const [inputState, setInputState] = useState(initialInputState);

  const handleInputChange = (index, value) => {
    setInputState((prevInputState) => {
      const updatedInputState = [...prevInputState];
      updatedInputState[index].value = value;
      return updatedInputState;
    });
  };
  const handleAccept = () => {
    const mainData = inputState.reduce((acc, input) => {
      acc[input.key] = input.value;
      return acc;
    }, {});
    dispatch(
      submitAgencyDetailsAPI(
        { ...mainData, quoteId: data1?.data?.header?.ticketId },
        setOpen,
      ),
    );
  };

  const handleReject = () => {
    const data = {
      quoteRequestId: data1?.data?.header?.ticketId,
      isQuoteApproved: false,
      quoteDeclineReason: "test",
    };
    dispatch(shareQuoteApprovalAPI(data, setOpen));
  };
  return (
    <div
      style={{
        width: "100%",
        padding: "0 30px 0 0",
      }}
    >
      <Heading text="Shared Quotes" type="withborder" />
      <div style={{ marginTop: "16px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          {data1?.data?.offlineQuotes?.map((i) => (
            <QuoteCardBox
              quoteData={i}
              key={i?.quoteRequestId}
              quoteState={quoteState}
            />
          ))}
        </div>

        {confirm && (
          <DialogBox
            width={500}
            open={confirm}
            setOpen={setConfirm}
            children={
              <CutomeModalNoQuote
                setOpen={setConfirm}
                title={"Are you sure?"}
                // subTitle={
                //   "You will be notified once partner accept/reject the quotes"
                // }
                confirmButton={"Reject quote"}
                cancelButton={"Cancel"}
                onClick={() => {
                  handleReject();
                  setConfirm(false);
                }}
              />
            }
          />
        )}

        <div>
          <Heading text="Inspection Details" type="withborder" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                padding: "12px",
                background: "var(--White, #FFF)",
                boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.25)",
              }}
            >
              <h1
                style={{
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#000",
                }}
              >
                Customer Details
              </h1>
              <div
                style={{
                  backgroundColor: "#E8F3FC",
                  padding: "12px",
                  borderRadius: "16px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  {data1?.data?.customerDetails?.map((i) => (
                    <div key={`customer-details-${i?.key}`}>
                      <h3
                        style={{
                          fontSize: "10px",
                          fontWeight: 400,
                          color: "var(--color-grey-40)",
                        }}
                      >
                        {i?.key}
                      </h3>
                      {i?.key && (
                        <p style={{ fontSize: "12px", fontWeight: 400 }}>
                          {i?.value}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {/* First*/}
              {!open && (
                <div>
                  <h1
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      color: "#000",
                      marginBottom: "10px",
                    }}
                  >
                    Update Agency Details
                  </h1>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "10px",
                      justifyContent: "center",
                    }}
                  >
                    {inputState?.map((input, index) => (
                      <input
                        key={`agency-input-${index}`}
                        type="text"
                        placeholder={input?.placeHolder}
                        style={{
                          border: "1px solid #CCCCCC",
                          borderRadius: "8px",
                          height: "40px",
                          width: "49%",
                          padding: "8px",
                        }}
                        value={input.value}
                        onChange={(event) =>
                          handleInputChange(index, event.target.value)
                        }
                      />
                    ))}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      marginTop: 24,
                    }}
                  >
                    <Button
                      onClick={handleAccept}
                      style={{
                        backgroundColor: "#0691FC",
                        textTransform: "none",

                        color: "#fff",
                        borderRadius: "8px",
                        fontSize: "14px",
                        fontWeight: 600,
                        width: "116px",
                        height: "45px",
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              )}

              {/* Second */}
              {open && (
                <SuccessModal
                  title={ "Agency Details Shared Successfully!"}
                  subTitle={
                    "You will be notified once agency completes the inspection"
                  }
                  // btnDisabled
                  shareButton="Okay"
                  onClick={() => {
                    setOpen(false);
                    dispatch(getQuoteDetailAPI(quoteId));
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyDetailsSubmit;
