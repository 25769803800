import { combineReducers } from "redux";
import authReducer from "./auth";
import partnerReducer from "./partner";
import quoteReducer from "./quote";
import mappingRequestsReducer from "./mappingRequests";
import gridReducer from "./grid";
import salesMappingReducer from "./salesMapping";
import { salesPartnerReducer } from "./salesPartner";

export default combineReducers({
  auth: authReducer, // Authentication
  partner: partnerReducer, // List
  quote: quoteReducer, // quote
  mappingRequests: mappingRequestsReducer, // Mapping Requests
  grid: gridReducer,
  salesMapping: salesMappingReducer,
  salesPartner: salesPartnerReducer,
});
